import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Marker, Popup } from "react-leaflet"
import L from "leaflet"

import ShareLocationButton from "../share/ShareLocationButton";

import useParticipantChangesListener from "../../hooks/useParticipantChangesListener";
import useGetParticipantIsOutOfCompetition from "../../hooks/useGetParticipantIsOutOfCompetition";

import { unixToDateAndHour } from "../../utils/unixToDateAndHour";

import { baseUrl } from '../../urls';

const ParticipantMarker = ({ participantData, alerts, exceededTimeParticipants }) => {

    const { t } = useTranslation();
    const participantId = participantData.id
    const participant = useParticipantChangesListener(participantId)

    const [location, setLocation] = useState()

    const [participantAlert, setParticipantAlert] = useState([])
    const isOutOfCompetition = useGetParticipantIsOutOfCompetition(participantId)
    const [isExceededTime, setIsExceededTime] = useState(false)
    const [icon, setIcon] = useState()
    const nameInitials = participant.name && participant.last_name ? Array.from(participant.name)[0] + Array.from(participant.last_name)[0] : participantData.data.bib_number

    let DefaultIconA = L.divIcon({
        className: 'text-white text-xs font-bold',
        html: `<div class="flex justify-center items-center w-6 h-6 bg-green-500 rounded border border-green-600">
                    <p>${nameInitials}</p>
                </div>`,
        iconSize: [5, 5],
    })

    let DefaultIconB = L.divIcon({
        className: 'text-white text-xs font-bold',
        html: `<div class="flex justify-center items-center w-6 h-6 bg-red-500 rounded boder border-red-600">
                    <p>${nameInitials}</p>
               </div>`,
        iconSize: [5, 5],
    })

    let DefaultIconC = L.divIcon({
        className: 'text-white text-xs font-bold',
        html: `<div class="flex justify-center items-center w-6 h-6 bg-cyan-500 rounded border border-cyan-600">
                    <p>${nameInitials}</p>
               </div>`,
        iconSize: [5, 5],
    })



    useEffect(() => {

        if (participant.last_location_id === undefined) return

        const url = `${baseUrl}seguimiento/getlocation/${participant.last_location_id}/location`

        const getParticipantLocation = async () => {
            const data = await fetch(url, {
                method: 'GET',
            })

            const response = await data.json()

            if (response !== undefined) {
                setLocation(response)
            }
        }

        if (participant.last_location_id !== undefined) getParticipantLocation()

    }, [participant])

    useEffect(() => {

        const participantAlert = alerts.filter(alert => alert.data.participant_id === participantId);

        setParticipantAlert(participantAlert);

    }, [alerts, participantId])

    useEffect(() => {

        const exceededTimeParticipant = exceededTimeParticipants.includes(participantId);

        setIsExceededTime(exceededTimeParticipant);

    }, [exceededTimeParticipants, participantId])



    useEffect(() => {
        if (participant) {
            if (participantAlert.length > 0 && isExceededTime) {
                setIcon(DefaultIconB)
            } else if (participantAlert.length > 0) {
                setIcon(DefaultIconB)
            } else if (isExceededTime) {
                setIcon(DefaultIconC)
            } else {
                setIcon(DefaultIconA)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExceededTime, participantAlert, participant])

    

    return (
        <div>
            {location && !isOutOfCompetition && !participant.ended &&
                <Marker
                    id={participant.bib_number}
                    position={[location.payload.lat, location.payload.lng]}
                    icon={icon}
                >
                    <Popup maxWidth="150">
                        <p className="text-slate-100 font-bold">{participant.name} {participant.last_name}</p>
                        <p className="text-slate-200">{t('Bib')}: {participant.bib_number}</p>
                        <p className="text-slate-200">Lat: {location.payload.lat.toFixed(4)}</p>
                        <p className="text-slate-200">Lng: {location.payload.lng.toFixed(4)}</p>
                        <p className="text-slate-100 font-bold">{unixToDateAndHour(location.payload.date, true)}</p>
                        {participantAlert.length > 0 && <p className="text-red-500">{t('Alert')}</p>}
                        <ShareLocationButton lat={location.payload.lat} lng={location.payload.lng} bib={participant.bib_number} />
                    </Popup>
                </Marker>
            }
        </div>
    )
}

export default ParticipantMarker