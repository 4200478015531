import { useState, useEffect } from 'react';

import { collection, onSnapshot, query, where } from 'firebase/firestore';

import { db } from '../firebase';

/**
 * The `useExceededTimeListener` function is a custom React hook that listens for changes in a
 * Firestore collection and returns the participants who have exceeded the time limit for a given
 * competition.
 * @param competitionId - The competitionId parameter is the ID of the competition for which we want to
 * listen for exceeded time participants.
 * @returns The `exceededTimeParticipants` array is being returned.
 */
const useExceededTimeListener = (competitionId) => {

    const [exceededTimeParticipants, setExceededTimeParticipants] = useState([])

    useEffect(() => {
        
        const ref = collection(db, 'time');
        const q = query(ref, where("competition_id", "==", competitionId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {

            if (querySnapshot.empty) {
                return []
            }

            const exceededTimeParticipants = querySnapshot.docs.map(doc => {
                return {
                    id: doc.id,
                    data: doc.data()
                }
            })            
            setExceededTimeParticipants(exceededTimeParticipants[0].data.participants)       
                       
        })

        return () => unsubscribe()

    }, [competitionId])

    
    return exceededTimeParticipants

};

export default useExceededTimeListener;