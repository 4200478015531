import { useState, useEffect } from "react";

import { collection, query, where, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

const useStaffDataListener = (userId) => {
    
        const [staff, setStaff] = useState([])
    
        useEffect(() => {
    
            const staffRef = collection(db, "staff");
            const q = query(staffRef, where("user_id", "==", userId), orderBy("name"));
    
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                try {
                    const queryStaff = querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setStaff(queryStaff)
                } catch (error) {
                    //handle error
                    console.log(error)
                }    
            });
    
            return () => unsubscribe()
        }, [userId]);

        return staff;
};

export default useStaffDataListener;