import { useTranslation } from "react-i18next"

import UserNaVBar from "../views/user/UserNavBar"
import CompetitionDate from "../views/competition/CompetitionDate"
import AddParticipants from "../features/participant/AddParticipants"
import CompetitionTrack from "../views/competition/CompetitionTrack"
import CompetitionStaff from "../views/competition/CompetitionStaff"
import Loading from "../components/icon/Loading"
import ReturnButton from "../navigation/ReturnButton"

import handleAuthentication from "../hooks/useHandleAuthentication"

import getCompetitonIdFromPath from "../utils/getCompetitionIdFromPath"

import { FaWrench } from "react-icons/fa"


const CompetitionSetting = () => {

    const { t } = useTranslation()
    
    const user = handleAuthentication()
    const competitionId = getCompetitonIdFromPath(window.location.pathname)    
       

    return (
        <div className="">
            {user ?
                <div>                    
                    <UserNaVBar />         
                    <div className="p-3 flex justify-between">
                        <div className="ml-2 text-xl text-slate-600 flex"> 
                            <FaWrench className="mr-2 mt-1" />                             
                            <p>{t('Competition setting')}</p>                            
                        </div>   
                        <ReturnButton />                     
                    </div>       
                    <div className="w-full bg-slate-300 pb-0.5"></div>
                    
                    <div>                   
                        <CompetitionDate competitionId={competitionId} />                             
                        <AddParticipants competitionId={competitionId} />
                        <CompetitionTrack competitionId={competitionId} /> 
                        <CompetitionStaff competitionId={competitionId} userId={user.uid}/>
                    </div>            
                </div>
                :
               <Loading />
            }
        </div>
    )
}

export default CompetitionSetting