import DeleteAlertButton from "../../features/alert/DeleteAlertButton";

import { unixToHour } from "../../utils/unixToDateAndHour";


const AlertBox = ({ alert }) => {

    return (
        <div key={alert.id} className="flex gap-2 my-4">


            <div className="text-slate-300">{unixToHour(alert.data.date)}</div>

            <div className="font-bold px-1 text-red-400">Fuera del track</div>

            <div>
                <DeleteAlertButton alertId={alert.id} />
            </div>

        </div>
    )
};

export default AlertBox;