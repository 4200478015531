import { useEffect, useState } from "react";

/**
 * The useViewPort function returns the width of the viewport and updates it whenever the window is
 * resized.
 * @returns The `useViewPort` function returns the `width` value, which represents the current width of
 * the viewport.
 */
const useViewPort = () => {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return width;
}

export default useViewPort;
