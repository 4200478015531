import { useState } from "react";
import * as turf from "@turf/turf";

import SaveWaypointButton from "./SaveWaypointButton";

const AddWaypointsToTrack = ({ track, setMovingWayPoint }) => {

    const geoJson = JSON.parse(track.data()["competition_track"])
    const trackLength = turf.length(geoJson, { units: "kilometers" })
    const [currentWayPoint, setCurrentWayPoint] = useState(undefined)

    const handleNUmberInput = (number) => {        
        if (number === '0' || number > trackLength) {
            setMovingWayPoint(undefined)
            setCurrentWayPoint(undefined)
            return
        }     
        
        const point = turf.along(geoJson.features[0].geometry, number, { units: "kilometers" })
        const waypoint = {
            'lat': point.geometry.coordinates[1],
            'lng': point.geometry.coordinates[0],
            'km': number,
        }

        setMovingWayPoint(waypoint)
        setCurrentWayPoint(waypoint)
    }


    return (
        <div className="mt-2">                      
            <div className="flex gap-4 my-6">
                <label className="mt-2 text-slate-500">Km:</label>
                <input 
                    className="bg-slate-100 border border-slate-500 rounded text-gray-900 pl-2 text-lg w-1/3 max-h-10" 
                    type="number" placeholder="1.23" 
                    defaultValue={0} 
                    min={0} 
                    max={trackLength} 
                    step={0.5} 
                    onChange={(e) => handleNUmberInput(e.target.value)} 
                />
                <SaveWaypointButton waypoint={currentWayPoint} competitionId={track.data().competition_id} />
            </div>            
        </div>
    )
}

export default AddWaypointsToTrack