const Loading = () => {
    return (

        <div className="flex justify-center mt-40 md:mt-80">
            <div className="lds-ripple">
                <div></div><div></div><div></div>

            </div>

        </div>

    )
};

export default Loading;