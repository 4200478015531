import { baseUrl } from "../../urls";

import { FaTimes } from 'react-icons/fa';

const RemoverStaffFromCompetitionButton = ({ competitionId, staffId }) => {

    const url = `${baseUrl}staff/removeFromCompetition`;    

    const removerStaffFromCompetition = async () => {

        const res = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('userId')                
            },
            body: JSON.stringify({ competitionId: competitionId, staffId: staffId })
        });

        console.log(res)
    };

    const handleRemoverStaffFromCompetition = () => {
        removerStaffFromCompetition();
    };

    return(
        <button 
            className="rounded py-1 px-2 bg-red-400 hover:bg-red-500 font-bold text-white hover:bg-red-600 mt-2" 
            onClick={() => handleRemoverStaffFromCompetition() }
        >
            <FaTimes />
        </button>
    )


};

export default RemoverStaffFromCompetitionButton;