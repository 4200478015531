import { useTranslation } from "react-i18next";

import StaffBox from "../../components/box/StaffBox";

import useStaffDataListener from "../../hooks/useStaffDataListener";

const StaffList = () => {

    const { t } = useTranslation()

    const userId = localStorage.getItem('userId')

    const staff = useStaffDataListener(userId)
    
    return (
        <div className="p-1">
            {staff.length > 0
                ?
                <div>                    
                    {staff.map((staff) => (
                        <div key={staff.id}>                            
                            <StaffBox staff={staff} />
                        </div>
                    ))}
                </div> 
               
                :

                <div className="text-center text-slate-500 my-10 md:text-xl">
                    <h1>{t('You have not created staff yet')}</h1>
                </div>
            }
        </div>

    );
};

export default StaffList;