import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import ParticipantStatusCheckBox from "../../features/participant/ParticipantStatusCheckBox";
import AlertBox from "./AlertBox";

import useGetParticipantIsOutOfCompetition from "../../hooks/useGetParticipantIsOutOfCompetition";

import getFirstLastName from "../../utils/getFirstLastName";
import { unixToHour } from "../../utils/unixToDateAndHour";

import { FaAngleDown } from 'react-icons/fa';
import { FaAngleUp } from 'react-icons/fa';
import { FaPhone } from "react-icons/fa";
import { FaSatelliteDish } from "react-icons/fa";
import { MdOutlineSignalCellularNodata } from "react-icons/md";
import { FaFlagCheckered } from "react-icons/fa";


const ParticipantDataBox = ({ participant, alerts, exceededTimeParticipants, index }) => {

    const { t } = useTranslation();

    const [showParticipantData, setShowParticipantData] = useState(false);
    const [participantAlerts, setParticipantAlerts] = useState([]);
    const [exceededTimeParticipant, setExceededTimeParticipant] = useState(false);
    const isOutOfCompetition = useGetParticipantIsOutOfCompetition(participant.id);
    const toggleShowParticipantData = () => {
        setShowParticipantData(!showParticipantData);
    }

    useEffect(() => {
        const participantAlerts = alerts.filter(alert => alert.data.participant_id === participant.id);
        setParticipantAlerts(participantAlerts);
    }, [alerts, participant])

    useEffect(() => {
        const exceededTimeParticipant = exceededTimeParticipants.includes(participant.id);
        setExceededTimeParticipant(exceededTimeParticipant);
    }, [exceededTimeParticipants, participant])

    return (
        <div className="w-100 p-3 border-b border-slate-400 text-sm">
            <div className="hover:cursor-pointer flex justify-between" onClick={() => toggleShowParticipantData()}>
                {isOutOfCompetition
                    ?
                    <div className="flex gap-3">
                        <div className="font-bold text-white p-1 w-1/8 text-xs">-</div>
                        <div className="font-bold text-white p-1 w-1/8 text-xs">{participant.data.bib_number} </div>
                        <div className="font-bold text-white p-1 w-1/8 text-xs">-</div>
                    </div>
                    :
                    <div>
                        {participant.data.ended
                            ?
                            <div className="flex gap-3">
                                <div className="font-bold text-green-500 p-1 w-1/8 text-xs">F</div>
                                <div className="font-bold text-white p-1 w-1/8 text-xs">{participant.data.bib_number} </div>
                                <div className="font-bold text-white p-1 w-1/8 text-xs">
                                    <FaFlagCheckered className="text-white" />
                                </div>
                            </div>
                            :
                            <div className="flex gap-3">
                                <div className="font-bold text-cyan-500 p-1 w-1/8 text-xs text-xs">{participant.position}</div>
                                <div>
                                    {participantAlerts.length > 0 &&
                                        <div className="font-bold text-red-400 p-1 w-1/8 text-xs">{participant.data.bib_number} </div>
                                    }
                                    {participantAlerts.length === 0 && exceededTimeParticipant &&
                                        <div className="font-bold text-cyan-400 p-1 w-1/8 text-xs">{participant.data.bib_number} </div>
                                    }
                                    {participantAlerts.length === 0 && !exceededTimeParticipant &&
                                        <div className="font-bold text-green-400 p-1 w-1/8 text-xs">{participant.data.bib_number} </div>
                                    }
                                </div>
                                <div>
                                    {exceededTimeParticipant && participant.data.connected &&
                                        <div className="font-bold text-white p-1 w-1/8 text-xs">
                                            <MdOutlineSignalCellularNodata className="mt-0.5 rotate-90 text-red-400" />
                                        </div>
                                    }
                                    {!exceededTimeParticipant && participant.data.connected &&
                                        <div className="font-bold text-white p-1 w-1/8 text-xs">
                                            <FaSatelliteDish />
                                        </div>
                                    }
                                    {!participant.data.connected &&
                                        <div className="font-bold text-white p-1 w-1/8 text-xs">-</div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }



                <div className="text-slate-100 mt-1 text-center">
                    {isOutOfCompetition &&
                        <span className="line-through text-slate-500">
                            {participant.data.name ? participant.data.name : t('No data')} {getFirstLastName(participant.data.last_name)}
                        </span>
                    }
                    {!isOutOfCompetition &&
                        <div>
                            {participant.data.name ? participant.data.name : t('No data')} {getFirstLastName(participant.data.last_name)}
                        </div>
                    }

                </div>
                {(!showParticipantData)
                    ?
                    <FaAngleUp className="mt-1 ml-2 text-white" />
                    :
                    <FaAngleDown className="mt-1 ml-2 text-white" />}
            </div>

            {showParticipantData &&
                <div>
                    {participant.data.ended
                        ?
                        <div className="mt-2 text-slate-600">
                            <div className="flex my-3 ml-1">
                                <FaPhone className="mr-2 text-cyan-500" />
                                <span className="text-slate-100">
                                    {participant.data.phone_number ? participant.data.phone_number : t('No avaliable')}
                                </span>
                            </div>
                            <div className="text-white">
                                <p className="mb-2">
                                    {t('Finish line at')} {unixToHour(participant.data.last_location_date)}
                                </p>
                            </div>
                        </div>
                        :
                        <div className="mt-4 text-slate-600">
                            <div className="flex my-3 ml-1">
                                <FaPhone className="mr-2 text-cyan-500" />
                                <span className="text-slate-100">
                                    {participant.data.phone_number ? participant.data.phone_number : t('No avaliable')}
                                </span>
                            </div>
                            <div>
                                {participantAlerts.length > 0 && participantAlerts.map(alert => (
                                    <AlertBox key={alert.id} alert={alert} />
                                ))}
                                {participantAlerts.length === 0 &&
                                    <div className="text-slate-600">
                                        <p className="mb-2 text-green-400 my-4">{t('No alerts')}</p>
                                    </div>}
                            </div>
                            <div>
                                {exceededTimeParticipant &&
                                    <div className="text-slate-200">
                                        <p className="my-4">
                                            {t('Last location sended at')}
                                            <span className="font-bold text-cyan-400">
                                                &nbsp;{unixToHour(participant.data.last_location_date)}
                                            </span>
                                        </p>
                                    </div>
                                }
                            </div>
                            <div className="flex justify-between mt-6">
                                <ParticipantStatusCheckBox
                                    key={participant.id}
                                    participant={participant}
                                    isParticipantOutOfCompetition={isOutOfCompetition}
                                />
                            </div>
                        </div>
                    }

                </div>
            }

        </div>
    )

}

export default ParticipantDataBox;
