import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { auth } from "../firebase"
import { signInWithEmailAndPassword } from "firebase/auth"

import ErrorNotice from "../components/notice/ErrorNotice"

const Login = () => {

    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [showError, setShowError] = useState(false)

    const handleSubmit = () => {

        signInWithEmailAndPassword(auth, email, password)
            .then((user) => {
                navigate('/')

            })
            .catch((error) => {
                console.log(error)
                setShowError(true)
            });
    }

    return (
        <div>
            <div className="flex justify-center mt-20 md:mt-48 w-100">
                <div className="border-2 rounded p-5 flex flex-col gap-5 w-5/6 md:w-2/4 lg:w-2/5 xl:w-1/4  bg-slate-800">
                    <div className="flex justify-center mt-10">
                        <img src="/st.png" alt="Safetrack logo" width={64} />
                        <p className="mt-5 text-2xl text-slate-200">Safetrack</p>
                    </div>
                    <p className="text-center text-slate-200">Welcome back!</p>
                    <div className="flex justify-center flex-col gap-5 mt-5 w-5/6 mx-auto">
                        <input
                            className="rounded p-3"
                            type="email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            className="rounded p-3"
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="flex justify-center mx-auto mt-4 mb-10">
                            <button
                                className="rounded py-3 px-6 bg-green-400 hover:bg-green-500 font-bold text-white"
                                onClick={() => handleSubmit()}
                            >
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center mt-10">
                {showError &&
                    <ErrorNotice
                        notice="Invalid credentials"
                    />
                }
            </div>
        </div>
    )
}

export default Login