import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import BibQrTable from "../../views/participant/BibQrTable";
import LoadingIconGreen from "../../components/icon/LoadingIconGreen";
import ErrorNotice from "../../components/notice/ErrorNotice";
import WarningNotice from "../../components/notice/WarningNotice";

import getParticipantsByCompetitionId from "../../services/getParticipantsByCompetitionId";

import { transformParticipantsObjectToSave } from "../../utils/transformParticipantsObjectToSave";


import { baseUrl } from '../../urls';

const BibQrGenerator = ({ competitionId }) => {

    const { t } = useTranslation();

    const [start, setStart] = useState(1);
    const [end, setEnd] = useState(5);
    const [bibNumbersCount, setBibNumbersCount] = useState(0);
    const [participants, setParticipants] = useState(null);
    const [isLoading, setIsLoading] = useState(true);  


    const url = `${baseUrl}participants/add/${competitionId}`

    const fetchParticipants = async () => {

        const participantsArray = await getParticipantsByCompetitionId(competitionId);
        setParticipants(participantsArray);
        setIsLoading(false);
    }

    const createEmptyParticipants = async (start, end, competitionId) => {
        if (bibNumbersCount > 0) {
            const object = transformParticipantsObjectToSave(start, end, competitionId)

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': localStorage.getItem('userId')
                },
                body: JSON.stringify(object)
            })


            if (response.status === 200) {
                fetchParticipants();
            }

        } else {
            return;
        }
    }

    useEffect(() => {
        
        if (start > end) {
            setBibNumbersCount(0);
            return;
        }

        if (start < 0 || end < 0) {
            setBibNumbersCount(0);
            return;
        }

        const calculateBibNumbersCount = end - start + 1;
       

        setBibNumbersCount(calculateBibNumbersCount);

    }, [start, end])

    useEffect(() => {
        if (!participants) fetchParticipants();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchParticipants])

    return (

        <div>
            {isLoading
                ?
                <div className='flex justify-center'>
                    <LoadingIconGreen />
                </div>
                :
                <div>
                    {participants.length === 0 &&
                        <div>
                            <p className="text-slate-800">{t('Generate')}</p>
                            <p className="my-5 text-sm text-slate-500">{t('Select range')}:</p>
                            <div className="">
                                <div>
                                    <label htmlFor="start" className="mt-1 text-slate-500">{t('From')}: </label>
                                    <input type="number" min={1} value={start} className="border border-slate-800 rounded-md p-1 w-20" onChange={(e) => setStart(e.target.value)} />
                                </div>
                                <div className="my-3">
                                    <label htmlFor="end" className="mt-1 text-slate-500">{t('To')}: </label>
                                    <input type="number" min={5} value={end} className={`border border-slate-800 rounded-md p-1 w-20 ml-0.5 ${window.localStorage.i18nextLng === "en" ? "ml-4" : ""}`} onChange={(e) => setEnd(e.target.value)} />
                                </div>
                            </div>
                            <div className="my-5">
                                <p className="my-3 text-sm text-slate-800">{t('Number of bib numbers')} <span className="font-bold">{bibNumbersCount}</span></p>
                            </div>

                            {bibNumbersCount > 500 &&
                                <ErrorNotice notice={'Over limit'} />
                            }

                            {bibNumbersCount < 5 &&
                                <ErrorNotice notice={'Under limit'} />
                            }
                            {(start === '0' || end === '0')&&
                                <ErrorNotice notice={'Zero Qr Error'} />
                            }
                            
                            {bibNumbersCount >= 5 && bibNumbersCount <= 500 &&
                                <div>
                                    <button className="border bg-green-500 text-white rounded-md px-3 py-2 my-2" onClick={() => createEmptyParticipants(start, end, competitionId)}>Generate QR codes</button>
                                    <WarningNotice notice={'Generation warning'} />
                                </div>
                            }   
                            

                        </div>
                    }
                    {participants.length > 0 &&

                        <BibQrTable participants={participants} createEmptyParticipants={createEmptyParticipants}/>

                    }
                </div>
            }
        </div>
    );
};

export default BibQrGenerator;