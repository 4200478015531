import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ConnectionInfo from "./ConnectionInfo";
import ParticipantDataBox from "../../components/box/ParticipantDataBox";

import { FaClipboardList } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaExclamationCircle } from "react-icons/fa";
import { FaMars } from "react-icons/fa";
import { FaVenus } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";

const MobileParticipantsList = ({ participants, alerts, exceededTimeParticipants }) => {

    const { t } = useTranslation();

    const groups = ["All", "Alerts", "Male", "Female"]

    const [groupedParticipants, setGroupedParticipants] = useState([]);

    const [group, setGroup] = useState(groups[0]);


    const [showParticipants, setShowParticipants] = useState(false);
    const [filteredParticipants, setFilteredParticipants] = useState([]);

    const filterParticipants = (e) => {

        const filter = e.target.value;
        const filteredParticipants = groupedParticipants.filter(participant => {
            return participant.data.bib_number.toString().includes(filter) || participant.data.name.toLowerCase().includes(filter.toLowerCase()) || participant.data.last_name.toLowerCase().includes(filter.toLowerCase());
        });

        setFilteredParticipants(filteredParticipants);

    }

    useEffect(() => {

        if (group === groups[0]) setGroupedParticipants(participants);

        if (group === groups[1]) {

            const alertsId = alerts.map(alert => alert.data.participant_id);

            const filteredParticipants = participants.filter(participant => {
                return alertsId.includes(participant.id);
            });
            
            setGroupedParticipants(filteredParticipants);
        }

        if (group === groups[2]) {
            const filteredParticipants = participants.filter(participant => {
                return participant.data.gender === 'M'
            });
            setGroupedParticipants(filteredParticipants);
        }

        if (group === groups[3]) {
            const filteredParticipants = participants.filter(participant => {
                return participant.data.gender === 'F'
            });
            setGroupedParticipants(filteredParticipants);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participants, alerts, group])

    useEffect(() => {
        setFilteredParticipants(groupedParticipants);
    }, [participants, groupedParticipants])

    return (
        <div>
            <button 
                className="px-2 py-2 bg-slate-800 text-white rounded fixed bottom-2 left-0 mx-2 mb-4" 
                style={{ zIndex: '400' }} 
                onClick={() => setShowParticipants(!showParticipants)}
            >
                <FaClipboardList className="text-xl" />
            </button>

            {showParticipants &&
                <div className="h-screen overflow-y-scroll bg-slate-800">
                    <div className="">
                        <div className="flex ">
                            <div className="w-full p-3 flex justify-center hover:cursor-pointer" onClick={() => setGroup(groups[0])}>
                                {group === groups[0]
                                    ?
                                    <FaUsers className="text-cyan-500 text-2xl" />
                                    :
                                    <FaUsers className="text-white text-2xl" />
                                }

                            </div>
                            <div className="w-full p-3 flex justify-center hover:cursor-pointer" onClick={() => setGroup(groups[1])}>
                                {group === groups[1]
                                    ?
                                    <FaExclamationCircle className="text-cyan-500 text-2xl" />
                                    :
                                    <FaExclamationCircle className="text-white text-2xl" />
                                }
                            </div>
                            <div className="w-full p-3 flex justify-center hover:cursor-pointer" onClick={() => setGroup(groups[2])}>
                                {group === groups[2]
                                    ?
                                    <FaMars className="text-cyan-500 text-2xl" />
                                    :
                                    <FaMars className="text-white text-2xl" />
                                }

                            </div>
                            <div className="w-full p-3 flex justify-center hover:cursor-pointer" onClick={() => setGroup(groups[3])}>
                                {group === groups[3]
                                    ?
                                    <FaVenus className="text-cyan-500 text-2xl" />
                                    :
                                    <FaVenus className="text-white text-2xl" />
                                }
                            </div>
                        </div>
                        <div className="flex p-4 gap-3 mx-2 mt-2">
                            <FaSearch className="text-slate-200 mt-2" />
                            <input 
                                className="p-1 rounded" 
                                type="text" 
                                placeholder={t('Search')} 
                                onChange={(e) => { filterParticipants(e) }} 
                            />                            
                        </div>
                    </div>
                    <ConnectionInfo participants={participants}/>
                    <div className="flex">
                        <div className="flex p-2 justify-around gap-2 text-white text-xs">
                            <p>Pos.</p>
                            <p>Bib</p>
                            <p>Con.</p>
                        </div>
                    </div>
                    {filteredParticipants.length >= 1 && filteredParticipants.map((participant) => (
                        <ParticipantDataBox
                            key={participant.id}
                            participant={participant}
                            alerts={alerts}
                            exceededTimeParticipants={exceededTimeParticipants}
                        />
                    ))}
                    {filteredParticipants.length === 0 &&
                        <p className="text-center text-slate-400 mt-10">Not participants found...</p>
                    }
                </div>
            }
        </div>
    );
};

export default MobileParticipantsList;
