import { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";


/**
 * The function `useParticipantChangesListener` is a custom hook in JavaScript that listens for changes
 * to a participant's data in a database and returns the updated data.
 * @param participantId - The `participantId` parameter is the unique identifier for a participant. It
 * is used to fetch the data for a specific participant from the database.
 * @returns The `data` variable is being returned.
 */
const useParticipantChangesListener = (participantId) => {

    const [data, setData] = useState([])

    useEffect(() => {

        
        const unsubscribe = onSnapshot(doc(db, 'participant', participantId), (doc) => {
            const data = doc.data()
            setData(data)
        }
        );               

        return () => unsubscribe()

    }, [participantId])


    return data
}

export default useParticipantChangesListener

