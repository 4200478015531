import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import WarningNotice from "../../components/notice/WarningNotice";

import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { FaQrcode } from "react-icons/fa";

const AddNewBibQr = ({ participants, createEmptyParticipants }) => {

    const { t } = useTranslation();

    const [qrFrom, setQrFrom] = useState(0);
    const [qrTo, setQrTo] = useState(0);
    const [value, setValue] = useState(0);
    const competitionId = participants[0].data.competition_id;
    const [showQrGenerator, setShowQrGenerator] = useState(false);

    useEffect(() => {
        const orderParticipantsByBib = participants.sort((a, b) => a.data.bib_number - b.data.bib_number);
        const from = orderParticipantsByBib[0].data.bib_number;
        const to = orderParticipantsByBib[orderParticipantsByBib.length - 1].data.bib_number;
        setQrFrom(from);
        setQrTo(to);
    }, [participants]);

    return (
        <div className="text-slate-800">
            <p className="my-6">{t('You have created')} <span className="text-slate-900 font-bold">{participants.length}</span> {t('bib numbers')} ({t('from')} {qrFrom} {t('to')} {qrTo})</p>
            <button
                className="border-2 border-slate-800 rounded text-slate-800 font-bold py-2 px-2 rounded flex gap-2 flex"
                onClick={() => setShowQrGenerator(!showQrGenerator)}
            >
                <FaQrcode className="text-lg" />
                {t('Generate new bib numbers')}

                {showQrGenerator
                    ?
                    <FaChevronDown className="mt-1 text-xs" />
                    :
                    <FaChevronUp className="mt-1 text-xs" />
                }
            </button>
            
            {showQrGenerator &&
                <div className="p-2 my-3">
                    <div>
                        <div>
                            <label htmlFor="start" className="mt-1 text-slate-600">{t('From')}: </label>
                            <input type="number" min={1} value={qrTo + 1} className="border border-gray-300 rounded-md p-1 my-1" disabled />
                        </div>
                        <div>

                            <div className="flex gap-2">
                                <div>
                                    <label htmlFor="end" className="mt-3 text-slate-600">{t('To')}: </label>
                                    <input
                                        type="number"
                                        min={qrTo + 1}
                                        initialvalue={qrTo + 1}
                                        className={`border border-gray-300 rounded-md p-1 mt-1 ${window.localStorage.i18nextLng === "en" ? "ml-4" : "ml-0.5"}`}
                                        onChange={(e) => setValue(e.target.value)}
                                    />
                                </div>
                                <button
                                    className="bg-green-500 hover:bg-green-600 text-white rounded-md px-2 py-1 mt-1"
                                    onClick={() => createEmptyParticipants(qrTo + 1, value, competitionId)}>
                                    {t('Gen')}
                                </button>

                            </div>
                            <div className="mt-5">
                                <WarningNotice notice="This can't be undone" />
                            </div>
                        </div>
                    </div>

                </div>
            }

        </div>
    )
};

export default AddNewBibQr;