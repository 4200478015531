import { useState, useEffect } from 'react';

import LoadingIconGreen from '../../components/icon/LoadingIconGreen';

import getParticipantQr from '../../services/getParticipantQr';

const QrBox = ({ participantId, onLoad }) => {    

    const [qrUrl, setQrUrl] =useState(null);
    const [qrLoaded, setQrLoaded] = useState(false);

    const getQrUrl = async () => {
        
            const url = await getParticipantQr(participantId)
        
            if (url) {       
                setQrUrl(url);     
                setQrLoaded(true);
                             
            } else {
                setTimeout(getQrUrl, 1000);
            }       
    };   

    useEffect(() => {
        getQrUrl();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[participantId])
    
    useEffect(() => {
        if (qrLoaded) {
            onLoad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [qrLoaded]);


    return (
        <div>
            {qrUrl ?
                <img src={qrUrl} className='w-2/3 h-auto' alt="qr code" />
                :
                <div className='flex justify-center'>
                    <LoadingIconGreen />
                </div>
            }
            
        </div>
    )
};

export default QrBox;