import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { FaCog } from "react-icons/fa";

const SettingsButton = () => {

    const { t } = useTranslation()

    const navigate = useNavigate()

    const navigateToSettings = () => {

        navigate('/settings')
    }

    return (
        <button className="flex py-2 px-3 gap-2 text-slate-500 hover:text-slate-700" onClick={() => navigateToSettings()}>
            <div className="mt-1">
                <FaCog />
            </div>
            <div>{t('Settings')}</div>
        </button>
    )
};

export default SettingsButton;