import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Marker, Popup } from "react-leaflet"
import L from "leaflet"

import { unixToDateAndHour } from "../../utils/unixToDateAndHour"

import useStaffChangesListener from "../../hooks/useStaffChangesListener"

import { baseUrl } from "../../urls"


const StaffMarker = ({ staffMember }) => {

    const { t } = useTranslation()

    const staff = useStaffChangesListener(staffMember.id)

    const [location, setLocation] = useState()

    const iconColor = {
        person:'bg-emerald-500',
        bicycle: 'bg-lime-500',
        car:'bg-fuchsia-500',
        ambulance: 'bg-blue-500',        
        lifeguard: 'bg-orange-500',  
        surveillance: 'bg-violet-500', 
        boat:'bg-amber-500'     
    }

    const iconName = {
        person:'fa-male',
        bicycle: 'fa-bicycle',
        car:'fa-car',
        ambulance: 'fa-ambulance',  
        lifeguard: 'fa-life-ring',
        surveillance: 'fa-binoculars',
        boat:'fa-ship'
    }

    console.log(staffMember.role)
    const icon = L.divIcon({
        className: `text-white text-lg`,
        html: `<div class="${iconColor[staffMember.role]} flex justify-center items-center w-6 h-6 rounded ">
                <i class="fa ${iconName[staffMember.role]}"></i>
               </div>`,
        iconSize: [10, 10],
    })

    
    useEffect(() => {

        if (staff.last_location === undefined) return

        const url = `${baseUrl}seguimiento/getlocation/${staff.last_location}/location`

        const getStaffLocation = async () => {
            const data = await fetch(url, {
                method: 'GET',
            })

            const response = await data.json()

            if (response !== undefined) {
                setLocation(response)
            }
        }

        if (staff.last_location !== undefined) getStaffLocation()


    }, [staff])

    return (

        <div>
            {location &&
                <Marker
                    position={[location.payload.lat, location.payload.lng]}
                    icon={icon}
                    size={[5, 5]}
                >
                    <Popup>
                        <div maxWidth="150">
                            <p className="text-slate-100 font-bold">{staffMember.name}</p>
                            <p className="text-slate-200 capitalize">{t(staffMember.role)}</p>
                            <p className="text-slate-200">{staffMember.phone_number}</p>
                            <p className="text-slate-100 font-bold">{unixToDateAndHour(location.payload.date, true)}</p>
                        </div>  
                    </Popup>
                </Marker>
            }
        </div>
    )
}

export default StaffMarker