import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { baseUrl } from '../../urls';

import CompetitionStaffBox from '../../components/box/CompetitionStaffBox';
import WarningNotice from "../../components/notice/WarningNotice";
import MyStaffButton from '../../navigation/MyStaffButton';

import useStaffDataListener from '../../hooks/useStaffDataListener';


const AddStaffToCompetition = ({ competitionId, userId }) => {

    const { t } = useTranslation();

    const url = `${baseUrl}staff/addToCompetition`;

    const staffData = useStaffDataListener(userId);

    const [assignedStaff, setAssignedStaff] = useState([]);

    const [unAssignedStaff, setUnAssignedStaff] = useState([]);

    const [selectedStaff, setSelectedStaff] = useState();

    useEffect(() => {
        if (staffData.length > 0) {
            const staff = staffData.filter(staff => staff.competitions.includes(competitionId));
            setAssignedStaff(staff);
        }
    }, [staffData, competitionId]);

    useEffect(() => {
        if (staffData.length > 0) {
            const staff = staffData.filter(staff => !staff.competitions.includes(competitionId));
            setUnAssignedStaff(staff);
        }
    }, [staffData, competitionId]);

    const addStaff = async () => {
        
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('userId')
            },
            body: JSON.stringify({ staffId: selectedStaff, competitionId }),
        });

        console.log(res);
        console.log(selectedStaff); 
    };

    const handleAddStaff = async () => {
        await addStaff();
        setSelectedStaff(unAssignedStaff[0].id);
        if(unAssignedStaff.length === 1){
            setSelectedStaff(unAssignedStaff[0].id)
        }       
    }

    return (
        <div className="mb-10 p-2">
            {staffData &&
                <div>
                    {unAssignedStaff.length > 0 &&
                        <div className=''>
                            <p className='text-slate-600 my-4'>{t('add staff')}</p>
                            <select
                                onChange={(e) => setSelectedStaff(e.target.value)}
                                className="block py-2 bg-slate-300 text-slate-600 rounded border-2 border-slate-500"
                            >
                                <option disabled selected>{t('Select member')}</option>

                                {unAssignedStaff.map((staff) => (
                                    <option key={staff.id} value={staff.id} >
                                        {staff.name} - {t(staff.role)}
                                    </option>
                                ))}
                            </select>
                            {selectedStaff &&
                                <button
                                    onClick={() => handleAddStaff()}
                                    className='bg-green-500 text-white py-2 px-3 rounded my-4'
                                >
                                    {t('Add')}
                                </button>
                            }
                        </div>
                    }

                    <div className='mt-5'>
                        <div className="text-slate-900 mt-1">
                            {assignedStaff.length > 0 &&
                                <div className=''>
                                    {assignedStaff.map((staff) => (
                                        <CompetitionStaffBox key={staff.id} competitionId={competitionId} staff={staff} />
                                    )
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }

            {staffData.length === 0 &&
                <div className="text-slate-600 mt-1">                   
                    <p className='ml-1 text-sm mt-4'>{t('Start creating staff')}:</p>
                    <div className='flex justify-start my-5'>
                        <div className='border border-2 border-slate-500 rounded'>
                            <MyStaffButton />
                        </div>
                    </div>
                    <WarningNotice notice="Can not assign staff" />
                </div>
            }
        </div>
    );
};

export default AddStaffToCompetition;