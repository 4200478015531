import { useState } from "react";

import { useTranslation } from "react-i18next";

import StaffList from "../../views/staff/StaffList";
import WarningNotice from "../../components/notice/WarningNotice";
import LoadingIconGreen from "../../components/icon/LoadingIconGreen";
import RoleIcons from "../../components/icon/RoleIcons";

import { baseUrl } from "../../urls";

import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { FaQuestion } from "react-icons/fa";
import { FaUser } from "react-icons/fa";

const CreateStaff = ({ userId }) => {

    const { t } = useTranslation()

    const [showAddStaff, setShowAddStaff] = useState(false)

    const url = `${baseUrl}staff/add`;

    const [name, setName] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [role, setRole] = useState()
    const [isCreating, setIsCreating] = useState(false)


    const addStaff = async () => {

        const staff = { name, phoneNumber, role, userId }
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('userId')
            },
            body: JSON.stringify(staff),
        })
        const data = await res.json()
        console.log(data)
    };

    const handleAddStaff = async () => {
        setIsCreating(true)
        await addStaff()  
        setName()  
        setPhoneNumber()  
        setRole()      
        setIsCreating(false)
        setShowAddStaff(false)        
    };

    return (
        <div className="mt-5 text-sm md:text-base ml-2">
            <div className="my-5">
                <StaffList />
            </div>
            <div
                className="p-2 flex gap-2 text-slate-700 hover:cursor-pointer hover:text-slate-800 flex justify-center"
            >
                <button
                    className="flex gap-2 rounded border border-2 border-slate-500 text-slate-500 font-bold px-3 py-2"
                    onClick={() => setShowAddStaff(!showAddStaff)}
                >
                    <FaUser className="text-lg" />
                    {t('Create new member')}
                    {showAddStaff
                        ?
                        <FaChevronDown className="mt-1"/>
                        :
                        <FaChevronUp className="mt-1"/>
                    }
                </button>
            </div>

            {showAddStaff &&
                <div className="p-2 flex justify-center">
                    <div>
                        <div className="mb-4">
                            <input
                                type="text"
                                placeholder={t('Name')}
                                className="rounded p-2"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="my-2 mb-4">
                            <input
                                type="text"
                                placeholder={t('Phone Number')}
                                className="rounded p-2"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </div>
                        <div className="flex my-2">
                            <select
                                className="bg-white rounded text-slate-600 p-2"
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <option value="" selected disabled>{t('Select role')}</option>
                                <option value="person">{t('person')}</option>
                                <option value="bicycle">{t('bicycle')}</option>
                                <option value="car">{t('car')}</option>
                                <option value="ambulance">{t('ambulance')}</option>
                                <option value="lifeguard">{t('lifeguard')}</option>
                                <option value="surveillance">{t('surveillance')}</option>
                                <option value="boat">{t('boat')}</option>
                            </select>
                            <div className="ml-3 mt-2">
                                {!role ?
                                    <div className="bg-slate-200 text-slate-200 rounded text-xl p-2">
                                        <FaQuestion />
                                    </div>
                                    :

                                    <RoleIcons role={role} />
                                }

                            </div>
                        </div>
                        {name && phoneNumber && role
                            ?
                            <div className="mt-6 flex justify-center">
                                {!isCreating ?
                                    <button
                                        className="bg-green-500 text-white rounded p-2 font-bold"
                                        onClick={() => handleAddStaff()}
                                    >
                                        {t('Create')}
                                    </button>
                                    :
                                    <LoadingIconGreen />
                                }

                            </div>
                            :
                            <div className="mt-5">
                                <WarningNotice notice={'Fill all the fields'} />
                            </div>

                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default CreateStaff;