import { useState } from "react";
import { read, utils } from "xlsx";
import { useTranslation } from 'react-i18next';

import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";

const MergeParticipantData = ({ handleSetFieldValues, handleSetParticipantData}) => {

    const { t } = useTranslation();

    const [showInput, setShowInput] = useState(false);
    
    const handleFileUpload = (e) => {
        const file = e.target.files[0];

        handleSetFieldValues([])
        handleSetParticipantData([])

        try {
            file.arrayBuffer().then((buffer) => {
                const wb = read(buffer, { type: 'buffer' });
                const ws = wb.Sheets[wb.SheetNames[0]];
                const data = utils.sheet_to_json(ws);
                handleSetParticipantData(data);
                handleSetFieldValues(Object.keys(data[0]));
            });
        } catch (error) {}
    };

    return (
        <div>
            <button
                className="bg-slate-800 rounded text-white font-bold py-2 px-2 rounded flex gap-2 flex my-6"
                onClick={() => setShowInput(!showInput)}
            >
                <FaClipboardList className="text-lg"/>
                {t('Update participants')}
                {showInput
                    ?
                    <FaAngleDown className="mt-1" />                    
                    :
                    <FaAngleUp className="mt-1" />
                }

            </button>

            {showInput &&
                <div>
                    <div className="my-6">
                        <input type="file" accept=".xls, .xlsx, .ods" onChange={(e) => handleFileUpload(e)}/>
                        <p className="my-2 text-sm text-slate-500">{t('Upload a file to add participants')}(.xls, .xlsx, .ods)</p>
                    </div>                
                </div>
            }

        </div>

    )
};

export default MergeParticipantData;