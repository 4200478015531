import DeleteStaffButton from "../../features/staff/DeleteStaffButton";
import RoleIcons from "../icon/RoleIcons";

const StaffBox = ({ staff }) => {

    return (
        <div className="bg-slate-700 rounded w-full md:w-2/4 mx-auto text-slate-200 flex justify-between gap-1 p-3 my-2">
            <RoleIcons role={staff.role} />
            <p className="mt-2 text-center">{staff.name}</p>
            <p className="mt-2 text-center text">{staff.phone_number}</p>
            <DeleteStaffButton staffId={staff.id} />
        </div>
    )
};

export default StaffBox;