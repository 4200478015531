import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { FaEye } from 'react-icons/fa'
import { FaEyeSlash } from 'react-icons/fa'

const WatchCompetitionButton = ({ competitionId, disable }) => {

    const {t} = useTranslation()

    const navigate = useNavigate()

    const handleWatchCompetition = () => {
        navigate(`/competition/view/${competitionId}`)
    }

    return (
        <span>
            {disable && 
                <button className="p-1 pt-2 pl-2 pr-4 bg-red-400 rounded flex pr-4" disabled onClick={() => handleWatchCompetition()}>
                    <FaEyeSlash className="text-xl text-white mr-2"/>
                    {t('Watch')}
                </button>
            }

            {!disable && 
                <button className="p-1 pt-2 pl-2 pr-4 rounded flex bg-green-500 hover:bg-green-600" onClick={() => handleWatchCompetition()}>
                    <FaEye className="text-xl text-white mr-2 rounded" />
                    {t('Watch')}
                </button>
            }
        </span>
    )
}

export default WatchCompetitionButton;