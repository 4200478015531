
const unixToDateAndHour = (unix, seconds = false) => {

    const date = new Date(unix * 1000);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    const formattedMinute = minute < 10 ? `0${minute}` : minute;
    const formattedSecond = second < 10 ? `0${second}` : second;

    if (seconds) return `${day}/${month}/${year} ${hour}:${formattedMinute}:${formattedSecond}`;

    return `${day}/${month}/${year} ${hour}:${minute}`;

}

const unixToHour = (unix) => {
    
        const date = new Date(unix * 1000);
        const hour = date.getHours();
        const minute = date.getMinutes();
    
        const formattedMinute = minute < 10 ? `0${minute}` : minute;
    
        return `${hour}:${formattedMinute}`;
}

export { unixToDateAndHour, unixToHour };