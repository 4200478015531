import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import CompetitionSetting from './pages/CompetitionSetting';
import CompetitionView from './pages/CompetitionView';
import Settings from './pages/Settings';
import Staff from './pages/Staff';
import NotFound from './pages/NotFound';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />   
        <Route path="/" element={<Home />} />     
        <Route path="/competition/setting/:competitionId" element={<CompetitionSetting />} />
        <Route path="/competition/view/:competitionId" element={<CompetitionView />} />
        <Route path="/settings" element={<Settings />} />   
        <Route path="/staff" element={<Staff />} />
        <Route path='*' element={<NotFound />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
