import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useGetWaypointCheckedParticipants from "../../hooks/useGetWaypointCheckedParticipants";

import { FaBullseye } from "react-icons/fa";

const WaypointList = ({ waypoint, participants, close }) => {

    const { t } = useTranslation();

    const checkedParticipants = useGetWaypointCheckedParticipants(waypoint.id)
   
    const [filteredUnCheckedParticipants, setFilteredUnCheckedParticipants] = useState([])    

    useEffect(() => {
        const removeOutOfCompetitionParticipants = participants.filter(participant => participant.data.out_of_competition === false)

        const filtered = removeOutOfCompetitionParticipants.filter(participant => !checkedParticipants.includes(participant.id))
        setFilteredUnCheckedParticipants(filtered)
    }, [participants, checkedParticipants])

    return (
        <div
            className="py-0.5 px-3 rounded bg-slate-800 border border-orange-400 fixed top-14 left-20 text-slate-200 cursor-default mr-2 overflow-y-scroll no-scrollbar max-h-80 text-xs max-w-60"
            style={{ zIndex: '400' }}
        >
            <div className="py-3">
                <div className="flex justify-between gap-4">
                    <div className="flex">
                        <FaBullseye className="inline-block mr-1 mt-0.5 text-orange-400" />
                        <p className="font-bold">Waypoint Km {waypoint.data.km}</p>
                    </div>
                    <p className="font-bold cursor-pointer hover:text-red-500" onClick={() => close()}>X</p>
                </div>
            </div>
            <div className="mt-3 relative">
                {checkedParticipants.length > 0
                    ?
                    <div className="">  
                                         
                        {filteredUnCheckedParticipants.length > 0 &&                            
                            <div className="mt-5">
                                <p>{t('Unregistered participants')}:</p>
                                {filteredUnCheckedParticipants.map((participant, index) => (
                                    <div className="my-2 grid grid-cols-4">
                                        <div className="col-span-1">
                                            <p className="text-white font-bold">{participant.data.bib_number} </p>
                                        </div>
                                        <div className="col-span-3">
                                            <p>{participant.data.name}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                        {filteredUnCheckedParticipants.length === 0 &&
                            <p className="my-4">{t('All participants have passed through the waypoint')}.</p>
                        
                        }
                    </div>
                    :
                    <p className="mb-4">{t('No participant has passed through the wayoint')}.</p>
                }
            </div>
        </div>

    )
}

export default WaypointList;