import useGetWaypointCheckedParticipants from "../../hooks/useGetWaypointCheckedParticipants";
import useCountParticipants from "../../hooks/useCountParticipants";

import { FaBullseye } from "react-icons/fa";

const WaypointCounter = ({ waypoint }) => {
    
    const checkedParticipants = useGetWaypointCheckedParticipants(waypoint.id)
    const totalParticipants = useCountParticipants(waypoint.data.competition_id)

    return (
        <div className="text-white font-bold text-center flex mt-1">
            <FaBullseye className="inline-block mr-1 mt-0.5 text-orange-400" />
            <p>{checkedParticipants.length} / {totalParticipants} </p>
        </div>
    )
}

export default WaypointCounter;