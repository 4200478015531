

import MapCompetitionView from "../components/maps/MapCompetitionView"
import ParticipantsList from "../views/participant/ParticipantsList"
import MobileParticipantsList from "../views/participant/MobileParticipantsList"
import Loading from "../components/icon/Loading"

import getCompetitonIdFromPath from "../utils/getCompetitionIdFromPath"

import HandleAuthentication from "../hooks/useHandleAuthentication"
import useViewPort from "../hooks/useViewPort";
import useAlertsDataListener from "../hooks/useAlertsDataListener"
import useExceededTimeListener from "../hooks/useExceededTimeListener"
import useGetParticipants from "../hooks/useGetParticipants"
import useStaffDataListener from "../hooks/useStaffDataListener"





const CompetitionView = () => {

    const user = HandleAuthentication()
   

    const competitionId = getCompetitonIdFromPath(window.location.pathname)   

    const alerts = useAlertsDataListener(competitionId)

    const exceededTimeParticipants = useExceededTimeListener(competitionId)
    
    const screenWidth = useViewPort();

    const participantsList = useGetParticipants(competitionId)

    const staffData = useStaffDataListener(window.localStorage.getItem('userId'))  

    const filteredStaff = staffData.filter(staff => staff.competitions.includes(competitionId))
    
   



    return (
        <div>            
            {user ? (
                <div className="flex bg-zinc-800">                                    
                    <div className="w-full">                   
                        <MapCompetitionView 
                            competitionId={competitionId} 
                            alerts={alerts} 
                            participants={participantsList}
                            exceededTimeParticipants={exceededTimeParticipants}
                            staff={filteredStaff}
                        />
                    </div>
                    {screenWidth > 1024 ?
                         <ParticipantsList 
                            competitionId={competitionId} 
                            alerts={alerts} 
                            participants={participantsList} 
                            exceededTimeParticipants={exceededTimeParticipants}
                        />
                         :
                        <MobileParticipantsList 
                            competitionId={competitionId} 
                            alerts={alerts} 
                            participants={participantsList} 
                            exceededTimeParticipants={exceededTimeParticipants} 
                            
                        />
                    }
                    
                </div>
            ) : (
                
                <Loading />
            )}

        </div>
    )
}

export default CompetitionView