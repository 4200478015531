import { useState, useEffect } from "react"
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";


/**
 * The function `useGetCompetitionConfigLevel` is a custom React hook that calculates the configuration
 * level of a competition based on its data.
 * @param competitionId - The `competitionId` parameter is the unique identifier for a competition. It
 * is used to fetch the competition data from the database and determine the configuration level of the
 * competition.
 */
const useGetCompetitionConfigLevel = ( competitionId ) => {

    const ConfigLevel = {
        NOT_CONFIGURED: 0,
        PARTIALLY_CONFIGURED: 1,
        FULLY_CONFIGURED: 2
    }

    const [configLevel, setConfigLevel] = useState(ConfigLevel.NOT_CONFIGURED)

    const getCompetition = async(competitionId) => {
        const docRef = doc(db, "competition", competitionId)
        const docSnap = await getDoc(docRef)
        
        return docSnap
        
    }
    

    const calculateConfigLevel = async() => {

        const competition = await getCompetition(competitionId)

        if (competition.data().track_updated && competition.data().ending_date && competition.data().starting_date && competition.data().participants_updated) {
            setConfigLevel(ConfigLevel.FULLY_CONFIGURED)
        }

        else if (competition.data().competition_track || competition.data().ending_date || competition.data().starting_date || competition.data().participants_updated) {
            setConfigLevel(ConfigLevel.PARTIALLY_CONFIGURED)
        }

        else {
            setConfigLevel(ConfigLevel.NOT_CONFIGURED)

        }        
    }

    useEffect(() => {

        calculateConfigLevel()        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [competitionId])

    
    return configLevel

}



export default useGetCompetitionConfigLevel