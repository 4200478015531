import { db } from '../firebase';
import { doc, getDoc } from "firebase/firestore";

const getParticipantQr = async (participantId) => {

   const docRef = doc(db, "participant", participantId);
   const docSnap = await getDoc(docRef);

   return docSnap.data().qr_url;
      
}

export default getParticipantQr;