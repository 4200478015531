import { useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmDeleteWindow from "../../windows/ConfirmDeleteWindow";

import { FaTrashAlt } from "react-icons/fa"

import { baseUrl } from '../../urls';

const DeleteAlertButton = ({ alertId }) => {

    const { t } = useTranslation()

    const url = `${baseUrl}alert/delete/${alertId}`
    const [showConfirmDeleteWindow, setShowConfirmDeleteWindow] = useState(false)
    const text = t('Are you sure you want to remove this alert?')

    const deleteAlert = async () => {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('userId')
            },
        })

        const data = await response.json()
        console.log(data)
    }

    const handleDeleteAlert = () => {
        setShowConfirmDeleteWindow(!showConfirmDeleteWindow)
        deleteAlert()
    }

    return (
        <div>
            <FaTrashAlt className="hover:cursor-pointer mt-1 text-slate-100 hover:text-red-500 mx-2" onClick={() => setShowConfirmDeleteWindow(!showConfirmDeleteWindow)} />
            {showConfirmDeleteWindow &&
                <ConfirmDeleteWindow deleteItem={handleDeleteAlert} closeWindow={() => setShowConfirmDeleteWindow(!showConfirmDeleteWindow)} text={text} />
            }
        </div>
    )
}

export default DeleteAlertButton;