import { useState } from "react";
import { useTranslation } from "react-i18next";

import LoadingIconGreen from "../../components/icon/LoadingIconGreen";


import { baseUrl } from "../../urls";

import { transformParticipantDataToSave } from "../../utils/transformParticipantsObjectToSave";


const UpdateParticipantsButton = ({ competitionId, data, handleSetFieldValues }) => {

    const { t } = useTranslation()

    const url = `${baseUrl}participants/update/${competitionId}`
    const [isUpdatingParticipants, setIsUpdatingParticipants] = useState(false)

  
    const updateParticipants = async (data) => {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('userId')
            },
            body: JSON.stringify(data)
        });

        return response;
    };

    const handleUpdateParticipants = async () => {
        setIsUpdatingParticipants(true);
        const updateData = transformParticipantDataToSave(data, competitionId);
        const response = await updateParticipants(updateData);

        if (response.status === 200) {   
            handleSetFieldValues([]);        
            setIsUpdatingParticipants(false);
        } else {
            setIsUpdatingParticipants(false);
        }
    };

    return (
        <div className="my-5">
            {!isUpdatingParticipants
                ?
                <button
                    className="border-2 rounded py-2 px-3 bg-green-500 hover:bg-green-500 font-bold text-white hover:bg-green-600"
                    onClick={() => handleUpdateParticipants()}
                >
                    {t('Add')}
                </button>
                :
                <div className='flex justify-center md:justify-start'>
                    <LoadingIconGreen />
                </div>
            }

        </div>
    )
};

export default UpdateParticipantsButton;