import { useState } from "react"
import { useTranslation } from "react-i18next"

import BibQrGenerator from "./BibQrGenerator"

import { FaChevronUp } from "react-icons/fa"
import { FaChevronDown } from "react-icons/fa"

const AddParticipants = ({ competitionId }) => {

    const { t } = useTranslation()
    
    const [showAddParticipants, setShowAddParticipants] = useState(false)

    return (
        <div className="mt-5 text-sm md:text-base ml-2" data-cy="add-participant-div">
            <div 
                className="p-2 flex gap-2 text-slate-700 hover:cursor-pointer hover:text-slate-800" 
                onClick={() => setShowAddParticipants(!showAddParticipants)}
            >
                {t('Participants')}
                {showAddParticipants 
                    ?
                    <FaChevronDown />
                    :
                    <FaChevronUp />
                }

            </div>
            {showAddParticipants &&
                <div className="p-2 ">
                    <BibQrGenerator competitionId={competitionId} />
                </div>
            }           
        </div>
    )
}

export default AddParticipants