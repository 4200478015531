import { useState, useMemo } from "react";
import { collection, query, where, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";


/**
 * The CompetitionDataListener function listens for changes in the "competition" collection in the
 * database for a specific user and returns the competitions data.
 * @param userId - The `userId` parameter is the unique identifier of the user for whom we want to
 * listen to competition data. It is used to filter the competitions based on the user's ID.
 * @returns The `competitions` array is being returned.
 */
const CompetitionDataListener = (userId) => {

    const [competitions, setCompetitions] = useState([])

    useMemo(() => {
        
        const competitionsRef = collection(db, "competition");
        const q = query(competitionsRef, where("user_id", "==", userId), orderBy("creation_date", "desc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const competitions = [];
            querySnapshot.forEach((doc) => {
                competitions.push({"id": doc.id, "data": doc.data()});
            })    
            
            setCompetitions(competitions)
        });

        return () => unsubscribe()

    }, [userId])
      
    return competitions
}

export default CompetitionDataListener;