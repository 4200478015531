//@todo validate file type
import { useState } from "react"
import { useTranslation } from "react-i18next"

import MapTrackSetting from "../../components/maps/MapTrackSetting"
import LoadingIconGreen from "../../components/icon/LoadingIconGreen"

import useGetCompetitionTrack from "../../hooks/useGetCompetitionTrack"

import { FaChevronUp } from "react-icons/fa"
import { FaChevronDown } from "react-icons/fa"

import { baseUrl } from '../../urls';

const CompetitionTrack = ({ competitionId }) => {

    const { t } = useTranslation()

    const url = `${baseUrl}track/add`
    const [track, setTrack] = useState(null)
    const competitionTrack = useGetCompetitionTrack(competitionId)
    const [responseStatus, setResponseStatus] = useState(null) //to handle errors and track upload process
    const [uploading, setUploading] = useState(false)
    const [responseMessage, setResponseMessage] = useState(null)
    const [showCompetitionTrack, setShowCompetitionTrack] = useState(false)
    const [showFileSizeError, setShowFileSizeError] = useState(false)

    const postTrack = async (file) => {
        //reset response status
        setResponseStatus(null)
        setResponseMessage(null)
        setUploading(true)

        const formData = new FormData()

        formData.append('file', file)
        formData.append('competitionId', competitionId)

        
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Token': localStorage.getItem('userId')
            },
            body: formData
        })

        setResponseStatus(response.status)
        setResponseMessage(await response.text())
        setUploading(false)        
    }

    const uploadTrack = async () => {       
        postTrack(track)
        setTrack(null)
    }

    const handleTrackChange = (e) => {
        setShowFileSizeError(false)
        const sizeInKiB = e.target.files[0].size / 1024;        
        if (sizeInKiB > 250) {
            setShowFileSizeError(true)
            e.target.value = "";
        }  else {
            setTrack(e.target.files[0])
        }
    }

    return (
        <div className="text-sm md:text-base mt-5 ml-2">
            <div className="p-2 flex gap-2 text-slate-700 hover:cursor-pointer hover:text-slate-800" onClick={() => setShowCompetitionTrack(!showCompetitionTrack)}>
                <p>{t('Competition track')}</p>
                {showCompetitionTrack ?
                    <FaChevronDown />
                    :
                    <FaChevronUp />
                }

            </div>
            {showCompetitionTrack &&
                <div className="p-2">
                    {!competitionTrack &&
                        <div>
                            <input className="mt-4 w-full" type="file" name="file" accept="gpx" onChange={(e) => handleTrackChange(e)} />
                            <p className="mt-2 text-slate-500">{t('Upload a .gpx, .kmz or .kml file')} </p>    
                            {showFileSizeError && <p className="mt-2 text-red-500">{t('File size must be less than 250 KiB')}</p>}
                        </div>
                    }

                    {track && !competitionTrack &&
                        <button className="border-2 rounded py-2 px-3 bg-green-500 hover:bg-green-600 font-bold text-white hover:bg-slate-500 mt-2" onClick={uploadTrack}>
                            {t('Upload track')}
                        </button>
                    }

                    {uploading &&
                        <div className="flex justify-center mt-5">
                            <LoadingIconGreen />
                        </div> 
                    }
                    {responseStatus === 400 && <p style={{ color: "red" }}>{responseMessage}</p>}
                    {competitionTrack &&
                        <div className="border border-2 rounded p-2">
                            <MapTrackSetting competitionTrack={competitionTrack} />
                        </div>
                    }
                </div>
            }

        </div>
    )
}

export default CompetitionTrack