import { useState } from "react";
import { useNavigate } from "react-router-dom";

import UserMenu from "./UserMenu";

import { FaUserCircle } from "react-icons/fa";

const UserNaVBar = () => {

    const [showUserMenu, setShowUserMenu] = useState(false);

    const navigate = useNavigate();

    const navigateHome = () => {
        navigate("/")
    }

    return (
        <div className="w-full bg-slate-800 flex justify-between" >
            <div className="text-white p-3 flex hover:cursor-pointer" onClick={() => navigateHome()}>
                <img src="/st.png" alt="Safetrack logo" width={48} height={48}/>            
                <p className="mt-2.5 text-xl font-bold">Safetrack</p>
            </div>
            <div 
                className="mt-5 mr-5"
                onClick={() => setShowUserMenu(!showUserMenu)} 
                onMouseEnter={() => setShowUserMenu(true)} 
                onMouseLeave={() => setShowUserMenu(false)} 
            >
                <FaUserCircle className="text-3xl text-white hover:cursor-pointer"/>
            </div>
            <div 
                className="fixed right-2 top-14" 
                onMouseEnter={() => setShowUserMenu(true)}
                onMouseLeave={() => setShowUserMenu(false)} 
            >
                {showUserMenu && <UserMenu />}
            </div>
        </div>
    )
}

export default UserNaVBar;