// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
//import { getStorage } from "firebase/storage";
// import { connectFirestoreEmulator } from "firebase/firestore";
// import { connectStorageEmulator } from "firebase/storage"
;

// Your web app's Firebase configuration
//production
const firebaseConfig = {
  apiKey: "AIzaSyCpZA0ibI1xmVq0T--mKD1WVCa8d4QN3Ws",
  authDomain: "safetrack-2b0e2.firebaseapp.com",
  projectId: "safetrack-2b0e2",
  storageBucket: "safetrack-2b0e2.appspot.com",
  messagingSenderId: "897937895985",
  appId: "1:897937895985:web:aac0a2ba60f8e3b482db10"
};

//development
// const firebaseConfig = {
//   apiKey: "AIzaSyBjp4x4UyESEVzs0sM5yIAIb1Va7DjB2-s",
//   authDomain: "safetrack-dev-29de2.firebaseapp.com",
//   projectId: "safetrack-dev-29de2",
//   storageBucket: "safetrack-dev-29de2.appspot.com",
//   messagingSenderId: "978470032552",
//   appId: "1:978470032552:web:3acb3623e242aabb6c5e9d"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
//for using local emulator
//connectFirestoreEmulator(db, '127.0.0.1', 8080);
export const auth = getAuth(app);


//const storage = getStorage(app);
//connectStorageEmulator(storage, "127.0.0.1", 9199);