import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import QrBox from '../../components/box/QrBox';
import AddNewBibQr from '../../features/participant/AddNewBibQr';
import DowloadBibQrTableButton from '../../features/participant/DownloadBibQrTableButton';
import MergeParticipantData from '../../features/participant/MergeParticipantData';
import UpdateParticipantsButton from '../../features/participant/UpdateParticipantsButton';
import WarningNotice from '../../components/notice/WarningNotice';
import ErrorNotice from '../../components/notice/ErrorNotice';


const BibQrTable = ({ participants, createEmptyParticipants }) => {

    const { t } = useTranslation();

    const competitionId = participants[0].data.competition_id;

    const fieldNames = [t('Bib Number'), t('Participant Name'), t('Last Name'), t('Phone Number'), t('Gender')];

    const [participantLoadedQrUrls, setParticipantLoadedQrUrls] = useState([]);
    const [allQrLoaded, setAllQrLoaded] = useState(false);

    const [fieldValues, setFieldValues] = useState([]);
    const [fields, setFields] = useState({ bibNumber: [], name: [], lastName: [], phoneNumber: [], gender: [] });
    const [participantData, setParticipantData] = useState();

    //bib number validation
    const [isBibNumberValid, setAreBibNumbersValid] = useState(true);
    const [areThereDuplicatedBibNumbers, setAreThereDuplicatedBibNumbers] = useState(false);
    const [areMoreBibNumbersThanQrCodes, setAreMoreBibNumbersThanQrCodes] = useState(false);
    const [areMoreQrCodesThanBibNumbers, setAreMoreQrCodesThanBibNumbers] = useState(false);


    const handleQrLoaded = (participantId) => {
        setParticipantLoadedQrUrls(prevState => [...prevState, participantId]);
    };

    const handleSetFieldValues = (fields) => {
        console.log('fields', fields)
        setFieldValues(fields);
    };

    const handleSetParticipantData = (participantData) => {
        console.log('participantData', participantData)
        setParticipantData(participantData);
    };

    const handleSetColumnValues = (index, fileColumnName) => {
        let tableColumnName = Object.keys(fields)[index]

        let arrayOfValues = []

        participantData.forEach((participant) => {
            arrayOfValues.push(participant[fileColumnName])
        })

        setFields((prevState) => ({
            ...prevState,
            [tableColumnName]: arrayOfValues
        }))
    };

    useEffect(() => {

        if (participantLoadedQrUrls.length === participants.length) setAllQrLoaded(true);

    }, [participantLoadedQrUrls, participants.length]);

    //bib number validation
    useEffect(() => {
        const bibNUmbers = fields.bibNumber        

        const checkIfNumberIsInteger = (value) => Number.isInteger(value) && value > 0

        const areAllBibNumbersValid = bibNUmbers.every(checkIfNumberIsInteger)

        if (!areAllBibNumbersValid) {
            setAreBibNumbersValid(false)
        } else {
            setAreBibNumbersValid(true)
        }

    }, [fields.bibNumber]);

    useEffect(() => {

        const bibNUmbers = fields.bibNumber

        const areThereDuplicatedBibNumbers = bibNUmbers.some((value, index) => bibNUmbers.indexOf(value) !== index)

        if (areThereDuplicatedBibNumbers) {
            setAreThereDuplicatedBibNumbers(true)
        } else {
            setAreThereDuplicatedBibNumbers(false)
        }

    },[fields.bibNumber])

    useEffect(() => {
        if (fields.bibNumber.length > participantLoadedQrUrls.length) {
            setAreMoreBibNumbersThanQrCodes(true)
        } else {
            setAreMoreBibNumbersThanQrCodes(false)
        }

        if (fields.bibNumber.length < participantLoadedQrUrls.length) {
            setAreMoreQrCodesThanBibNumbers(true)
        } else {
            setAreMoreQrCodesThanBibNumbers(false)
        }

    },[fields.bibNumber, participantLoadedQrUrls])

    return (
        <div>
            <div>
                <div>
                    <MergeParticipantData handleSetFieldValues={handleSetFieldValues} handleSetParticipantData={handleSetParticipantData} />
                </div>
            </div>
            <div>

                {fieldValues.length > 0 &&
                    <div className='mt-6'>
                        <p className='font-bold text-slate-600'>{t('Select the fields')}</p>

                        {fieldNames.map((field, index) => {
                            return (
                                <div className='my-2 grid grid-cols-2 md:w-1/3 lg:w-1/4' key={index}>
                                    <p className='my-1 text-slate-700'>{field}:</p>
                                    <select
                                        className="block py-2 bg-slate-300 text-slate-600 rounded border-2 border-slate-500"
                                        onChange={(e) => handleSetColumnValues(index, e.target.value)}
                                    >
                                        <option selected={true} disabled="disabled">{t('Select')}</option>
                                        {fieldValues.map((fileColumnName, index) => {
                                            return (
                                                <option key={index} value={fileColumnName}>{fileColumnName}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            )
                        })
                        }
                        {
                            fields.name.length !== 0 &&
                            fields.lastName.length !== 0 &&
                            fields.bibNumber.length !== 0 &&
                            fields.phoneNumber.length !== 0 &&
                            fields.gender.length !== 0 &&
                            isBibNumberValid &&
                            !areThereDuplicatedBibNumbers

                            ?
                                <div className='mt-10'>
                                    {areMoreBibNumbersThanQrCodes && <WarningNotice notice={t('There are more bib numbers than qr codes')} />}
                                    {areMoreQrCodesThanBibNumbers && <WarningNotice notice={t('There are more qr codes than bib numbers')} />}
                                    <UpdateParticipantsButton
                                        competitionId={competitionId}
                                        data={fields}
                                        handleSetFieldValues={handleSetFieldValues}
                                    />
                                </div>
                            :
                                <div className='mt-5'>
                                    {isBibNumberValid && areThereDuplicatedBibNumbers && <ErrorNotice notice={t('There are duplicated bib numbers')} />}
                                    {!isBibNumberValid && <ErrorNotice notice={t('Bib numbers must be positive integers')} />}
                                    <WarningNotice notice={t('Please fill all the fields')} />                                                                       
                                </div>
                        }
                    </div>
                }
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:w-3/4 gap-2 overflow-y-scroll h-96 ">

                {fields.bibNumber.length > 0
                    ?
                    participants.sort((a, b) => a.data.bib_number - b.data.bib_number).map((participant, index) => (
                        <div key={index} className="border border-gray-300 rounded-md p-3 flex">
                            <div>
                                <p className='my-1 text-2xl font-bold'> {participant.data.bib_number}</p>
                                <QrBox participantId={participant.id} onLoad={() => handleQrLoaded(participant.id)} />
                            </div>
                            <div>
                                <p className='mt-10'>{fields.name[fields.bibNumber.indexOf(participant.data.bib_number)]}</p>
                                <p className=''>{fields.lastName[fields.bibNumber.indexOf(participant.data.bib_number)]}</p>
                                <p className=''>{fields.phoneNumber[fields.bibNumber.indexOf(participant.data.bib_number)]}</p>
                                <p className=''>{fields.gender[fields.bibNumber.indexOf(participant.data.bib_number)]}</p>
                            </div>
                        </div>
                    ))
                    :

                    participants.sort((a, b) => a.data.bib_number - b.data.bib_number).map((participant, index) => (
                        <div key={index} className="border border-gray-300 rounded-md p-3 flex bg-slate-400">
                            <div>
                                <p className='my-1 text-2xl font-bold'> {participant.data.bib_number}</p>
                                <QrBox participantId={participant.id} onLoad={() => handleQrLoaded(participant.id)} />
                            </div>
                            <div className=''>
                                <p className='mt-10'>{participant.data.name ? participant.data.name : t('No data')}</p>
                                <p className=''>{participant.data.last_name}</p>
                                <p className=''>{participant.data.phone_number}</p>
                                <p className=''>{participant.data.gender}</p>
                            </div>
                        </div>
                    ))}
            </div>
            {allQrLoaded &&
                <div className='mt-3'>
                    <DowloadBibQrTableButton competitionId={competitionId} />
                    <AddNewBibQr participants={participants} createEmptyParticipants={createEmptyParticipants} />
                </div>
            }

        </div>

    )
};

export default BibQrTable;