import { useState, useEffect } from "react"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { useNavigate } from "react-router-dom"

/**
 * The function `HandleAuthentication` is a custom hook in JavaScript that handles user authentication
 * using Firebase and returns the current user.
 * @returns The `user` variable is being returned.
 */
const HandleAuthentication = () => {
    
    const [user, setUser] = useState(null)
    const navigate = useNavigate()
    const auth = getAuth()

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {                
                setUser(user)
                localStorage.setItem('userId', user.uid)
            } else {
                setUser(null)
                navigate('/login')
            }
        })
    }, [auth, navigate])

    return user

}

export default HandleAuthentication