import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { FaWrench } from "react-icons/fa"


const UpdateCompetitionButton = ({competitionId}) => {
    
    const { t } = useTranslation()

    const navigate = useNavigate()

    const handleUpdateCompetition = () => {
        
        navigate(`/competition/setting/${competitionId}`)
    }
    
    return(
        <button className="border-2 p-1 pt-2 pl-2 pr-4 rounded flex gap-1 text-slate-600 bg-slate-200 hover:bg-slate-300" onClick={() => handleUpdateCompetition()}>
            <FaWrench className="text-xl " />    
            <p>{t('Update')}</p>        
        </button>
    )
}

export default UpdateCompetitionButton;