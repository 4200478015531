import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { baseUrl } from '../../urls';

import ErrorNotice from '../../components/notice/ErrorNotice';

const SaveWaypointButton = ({ waypoint, competitionId }) => {
    
    const { t } = useTranslation() 

    const url = `${baseUrl}waypoint/${competitionId}/add`

    const [showErrorMessageWindow, setShowErrorMessageWindow] = useState(false)

    const saveWaypoint = async () => {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Token': localStorage.getItem('userId')
            },
            body: JSON.stringify(waypoint)
        })
        
        const data = await response.json(response)
                
        if (data.status === 'Max waypoints reached') {
            setShowErrorMessageWindow(true)
        }
    }
    
    const handleClick = () => {
        if (waypoint === undefined) return
        saveWaypoint()
    }

    return(
        <div>
            <button className="bg-green-500 text-white py-2 px-3 rounded" onClick={() => handleClick()}>
                {t('Set Waypoint')}
            </button>  
            <div className='mt-2'>
                {showErrorMessageWindow && <ErrorNotice notice={t('Max waypoints reached')} />}
            </div>                     
        </div>
    )
}

export default SaveWaypointButton