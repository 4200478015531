import { useTranslation } from 'react-i18next';

import { FaWhatsapp } from 'react-icons/fa'

const ShareLocationButton = ({ lat, lng, bib }) => {

    const { t } = useTranslation();

    const shareWhatsApp = () => {

        const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

        const message = t('Share') + `${bib}:\n\n ${googleMapsURL}`;

        const encodedMessage = encodeURIComponent(message);

        const whatsappURL = `https://wa.me/?text=${encodedMessage}`;

        window.open(whatsappURL, '_blank');
    }

    return (
        <button onClick={() => shareWhatsApp()} className="bg-green-500 text-white rounded p-1.5 flex gap-1">
            <FaWhatsapp className="text-xl" />
        </button>
    )

};

export default ShareLocationButton;