import { useEffect, useState } from "react";
import { where, query, collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

/**
 * The function `useGetTrackWaypoints` is a custom React hook that retrieves and updates a list of
 * waypoints from a Firestore collection based on a given competition ID.
 * @param competitionId - The `competitionId` parameter is the ID of the competition for which you want
 * to retrieve the track waypoints.
 * @returns The `waypoints` array is being returned.
 */
const useGetTrackWaypoints = (competitionId) => {
    const [waypoints, setWaypoints] = useState([]);

    useEffect(() => {

        const eventsRef = collection(db, "waypoint");
        const q = query(eventsRef, where("competition_id", "==", competitionId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const waypoints = querySnapshot.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                };
            });
            
            setWaypoints(waypoints);
        });

        return () => unsubscribe();
    }, [competitionId]);


    return waypoints;
}

export default useGetTrackWaypoints;