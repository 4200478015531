import { useTranslation } from "react-i18next"

import DeleteWaypointButton from "../../features/waypoint/DeleteWaypointButton"

const WaypointsTable = ({ waypoints, trackId }) => {

    const { t } = useTranslation()

    return (
        <div className="">
            <table className="bg-slate-800 mt-6 text-white">
                <thead>
                    <tr>
                        <th className="p-2" scope="col">{t('Index')}</th>
                        <th className="p-2" scope="col">Km</th>
                        <th className="p-2" scope="col">Lat</th>
                        <th className="p-2" scope="col">Long</th>
                        <th className="p-2" scope="col">{t('Delete')}</th>
                    </tr>
                </thead>
                <tbody>
                    {waypoints && waypoints.map((waypoint, index) => {
                        return (
                            <tr key={index}>
                                <th className="p-2" scope="row">{index + 1}</th>
                                <td className="p-2">{waypoint.km}</td>
                                <td className="p-2">{waypoint.lat.toFixed(2)}</td>
                                <td className="p-2">{waypoint.lng.toFixed(2)}</td>
                                <td className=" flex justify-center">
                                    <DeleteWaypointButton waypointId={waypoint.id} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {waypoints.length === 0 &&
                <p className="mt-2 text-slate-500">{t("Haven't created waypoints yet")}</p>
            }
        </div>
    )
}

export default WaypointsTable