import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import useGetCompetitionConfigLevel from "../../hooks/useGetCompetitionConfigLevel";
import WatchCompetitionButton from "../../navigation/WatchCompetitionButton";
import UpdateCompetitionButton from "../../navigation/UpdateCompetitionButton";
import DeleteCompetitionButton from "../../features/competition/DeleteCompetitionButton";
import ConfigLevelIcon from "../icon/ConfigLevelIcon";
import MapMiniature from "../maps/MapMiniature";

import getCompetitionParticipantsCount from "../../services/getCompetitionParticipantsCount";

import dateToString from "../../utils/dateToString";

const CompetitionBox = ({ competition }) => {

    const { t } = useTranslation()

    const competitionConfigLevel = useGetCompetitionConfigLevel(competition.id);
    const [participantsCount, setParticipantsCount] = useState(0);

    useEffect(() => {
        const getParticipantsCount = async () => {
            const count = await getCompetitionParticipantsCount(competition.id);
            setParticipantsCount(count);
        }
        getParticipantsCount();
    }, [competitionConfigLevel, competition.id])

    return (
        <div
            className="bg-slate-800 rounded mx-auto mb-12 mt-10 text-slate-100 w-full md:min-w-2/3 md:max-w-2/3 relative"
        >
            <div className="flex justify-between p-2 mt-3">
                <p className="text-2xl text-white capitalize">{competition.data.name}</p>
                <DeleteCompetitionButton competitionId={competition.id} />
            </div>
            <div className="px-2">
                {competitionConfigLevel === 2 ?
                    <WatchCompetitionButton competitionId={competition.id} />
                    :
                    <WatchCompetitionButton competitionId={competition.id} disable={true} />
                }

            </div>
            <div className="flex justify-between p-2 gap-1">
                <div className="relative mt-3">
                    <div className="p-1 border-2 text-slate-200 rounded bg-slate-900 mt-1">
                        <div className="flex justify-between">
                            <p className="text-sm md:text-xl">Status</p>
                            <ConfigLevelIcon level={competitionConfigLevel} />
                        </div>
                        <hr className="mt-1"></hr>

                        {competitionConfigLevel === 0 &&
                            <ul>
                                <p className="mt-2 text-xs md:text-base">{t('Upload a file with participants')}.</p>
                                <p className="mt-2 text-xs md:text-base">{t('Set competition dates')}.</p>
                                <p className="mt-2 text-xs md:text-base">{t('Upload a file with a track')}.</p>
                            </ul>
                        }
                        {competitionConfigLevel === 1 && !competition.data.starting_date ?
                            <p className="mt-2 text-xs md:text-base">{t('Set competition dates')}.</p>
                            :
                            ""
                        }

                        {competitionConfigLevel === 1 && !competition.data.track_updated ?
                            <p className="mt-2 text-xs md:text-base">{t('Upload a file with a track')}.</p>
                            :
                            ""
                        }

                        {competitionConfigLevel === 1 && !participantsCount ?
                            <p className="mt-2 text-xs md:text-base">{t('Upload a file with participants')}.</p>
                            :
                            ""
                        }

                        {competitionConfigLevel === 2 &&
                            <p className="my-5 text-xs md:text-base">{t('Everything is ready for the competition')}.</p>
                        }


                    </div>
                    <div className="absolute bottom-0">
                        <UpdateCompetitionButton competitionId={competition.id} />
                    </div>
                </div>
                <div className="mt-4 md:mt-0">
                    <MapMiniature competitionId={competition.id} />
                </div>
            </div>
            <div className="grid grid-cols-3 mt-5">
                <div className="border-2 border-l-0 border-b-0 border-slate-600 p-1">
                    <p className="text-slate-400 text-sm md:text-base">{t('Participants')}:</p>
                    {participantsCount ?
                        <p className="text-white text-xs md:text-base font-bold text-center">{participantsCount}</p>
                        :
                        <p className="text-white text-xs md:text-base font-bold text-center">0</p>
                    }

                </div>
                <div className="border-2 border-l-0 border-b-0 border-r-2 border-slate-600 p-1">
                    <p className="text-slate-400 text-sm md:text-base">{t('Starting date')}:</p>
                    {competition.data.starting_date ?
                        <p className="text-white text-xs md:text-base font-bold">{dateToString(competition.data.starting_date)}</p>
                        :
                        <p className="text-white text-xs md:text-base font-bold">{t('Not configured')}</p>
                    }
                </div>  <div className="border-2 border-l-0 border-b-0 border-t-2 border-r-0 border-slate-600 p-1">
                    <p className="text-slate-400 text-sm md:text-base">{t('Ending date')}:</p>
                    {competition.data.ending_date ?
                        <p className="text-white text-xs md:text-base font-bold">{dateToString(competition.data.ending_date)}</p>
                        :
                        <p className="text-white text-xs md:text-base font-bold">{t('Not configured')}</p>
                    }
                </div>
            </div>
        </div>
    );
}

export default CompetitionBox;