import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

const getCompetitionParticipantsCount = async (competitionId) => {

    const eventsRef = collection(db, "participant");
    const q = query(eventsRef, where("competition_id", "==", competitionId));
    const querySnapshot = await getDocs(q);
    const participantsCount = querySnapshot.size;

    return participantsCount;

}

export default getCompetitionParticipantsCount;