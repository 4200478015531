import { db } from '../firebase';
import { doc, getDoc} from 'firebase/firestore';

const getCompetitionTime = async (competitionId) => {

    const docRef = doc(db, "competition", competitionId);
    const querySnapshot = await getDoc(docRef);
    
    if (querySnapshot.empty) {
        return null
    }

    const startingTime =  querySnapshot.data().starting_time
    const endingTime = querySnapshot.data().ending_time     

    return {startingTime: startingTime, endingTime: endingTime}
}

export default getCompetitionTime;