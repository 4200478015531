import { Marker, Popup } from "react-leaflet"
import L from "leaflet"

import useGetWaypointCheckedParticipants from "../../hooks/useGetWaypointCheckedParticipants"

const WaypointMarker = ({ waypoint, inCompetition = false }) => {
    
    const waypointCheckedParticipants = useGetWaypointCheckedParticipants(waypoint.id)

    const DefaultIconC = L.icon({
        iconUrl: "/waypoint.png",
        iconSize: [24, 24],
    });

    
    return (
        <Marker position={[waypoint.data.lat, waypoint.data.lng]} icon={DefaultIconC}>
            <Popup>
                <p className="text-slate-200">Waypoint: </p>
                <p className="text-slate-200">Track kilometer: {waypoint.data.km}</p>
                {/* inCOmpetition prop serves to differenciate CompetitionMapView from MapTrackSetting */}
                {inCompetition && <p className="text-slate-200">Checked participants: {waypointCheckedParticipants.length}</p>}
            </Popup>
        </Marker >
    )
}

export default WaypointMarker