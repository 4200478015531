const ConfigLevelIcon = ({ level }) => {
    return (
        <div>
            {level === 0 && 
                <div className="flex justift-center gap-0.5">
                    <div className="bg-red-500 py-3 px-1 rounded"></div>
                    <div className="bg-slate-200 py-3 px-1 rounded"></div>
                    <div className="bg-slate-200 py-3 px-1 rounded"></div>
                </div>
            }
            {level === 1 && 
                <div className="flex justift-center gap-0.5">
                    <div className="bg-orange-500 py-3 px-1 rounded"></div>
                    <div className="bg-orange-500 py-3 px-1 rounded"></div>
                    <div className="bg-slate-200 py-3 px-1 rounded"></div>
                </div>
            }
            {level === 2 && 
                <div className="flex justift-center gap-0.5">
                <div className="bg-green-500 py-3 px-1 rounded"></div>
                <div className="bg-green-500 py-3 px-1 rounded"></div>
                <div className="bg-green-500 py-3 px-1 rounded"></div>
            </div>
            }
        </div>
    );
};

export default ConfigLevelIcon;