import { getAuth } from "firebase/auth"
import { useTranslation } from "react-i18next"

import { FaSignOutAlt } from "react-icons/fa"

const SignOutButton = () => {
    
    const { t } = useTranslation()

    const auth = getAuth()

    const handleSignOut = () => {
        auth.signOut()
    }
        
    return (
        <button className="flex py-2 px-3 gap-1 text-slate-500 hover:text-slate-700" data-cy="logout-button" onClick={() => handleSignOut()}>
            <div className="mt-1">
                <FaSignOutAlt /> 
            </div>    
            <div>
                {t('Sign out')}
            </div>
        </button>
    )
}

export default SignOutButton