const getFirstLastName = (lastName) => {
	
	if (!lastName) return null

	const lastNamesArray = lastName.split(" ")

	if (lastNamesArray.length === 1) return lastName

	if (lastNamesArray.length > 1) return lastNamesArray[0]

}	

export default getFirstLastName
