import HomeButton from "../../navigation/HomeButton"
import MyStaffButton from "../../navigation/MyStaffButton"
import SettingsButton from "../../navigation/SettingsButton"
import SignOutButton from "../../features/user/SignOutButton"

const UserMenu = () =>  {
    return (
        <div className="rounded bg-slate-300">
            <HomeButton />
            <MyStaffButton />
            <SettingsButton />
            <SignOutButton />
        </div>
    )
}

export default UserMenu