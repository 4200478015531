import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { FaHome } from "react-icons/fa";


const HomeButton = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const navigateToSettings = () => {

        navigate('/')
    }

    return (
        <button className="flex py-2 px-3 gap-2 text-slate-500 hover:text-slate-700" onClick={() => navigateToSettings()}>
            <div className="mt-1">
                <FaHome />
            </div>
            <div>{t('Home')}</div>
        </button>
    )
};

export default HomeButton;