import { useEffect, useState } from "react";

import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

/**
 * The `useCountParticipants` function is a custom React hook that returns the count of participants in
 * a competition based on the provided competition ID and if the participant is or not out_of_competition..
 * @param competitionId - The `competitionId` parameter is the unique identifier of the competition for
 * which you want to count the participants. It is used to filter the participants based on the
 * competition they are associated with.
 * @returns The `count` variable is being returned.
 */

const useCountParticipants = (competitionId) => {

    const [count, setCount] = useState(0)

    useEffect(() => {

        const participantsRef = collection(db, "participant");
        const q = query(participantsRef, where("competition_id", "==", competitionId), where("out_of_competition", "==", false));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            try {
                const queryCount =  querySnapshot.size
                setCount(queryCount)
            } catch (error) {
                //handle error
                console.log(error)
            }

        });

        return () => unsubscribe()


    }, [competitionId]);

    return count;
};

export default useCountParticipants;