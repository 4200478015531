import { useTranslation } from "react-i18next";

const ErrorMessageWindow = ({ closeWindow, text }) => {
    const { t } = useTranslation();

    return (
        <div className="text-center absolute bg-slate-500 border border-slate-200 rounded shadow p-4 text-sm md:text-base top-1/2 left-1/2 md:transform -translate-x-1/2 -translate-y-1/2" style={{ zIndex: '400' }}>
            <div className="">
                <h2 className="mt-2 text-slate-200">{text}</h2>
                <div className="flex justify-around w-1/2 mx-auto mt-3 gap-2">
                    <button className="border-2 rounded p-2 bg-slate-400 hover:bg-slate-600" onClick={closeWindow}>
                        {t('Close')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ErrorMessageWindow;