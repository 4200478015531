import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import WarningNotice from '../../components/notice/WarningNotice';
import ErrorNotice from '../../components/notice/ErrorNotice';

import { baseUrl } from '../../urls';

import { FaCalendarAlt } from 'react-icons/fa';

const SaveCompetitionDate = ({ competitionId }) => {

    const { t } = useTranslation()

    const userId = localStorage.getItem('userId')

    //to use as min value for date input
    const today = new Date().toISOString().split('T')[0];

    const [date, setDate] = useState({ startingDate: '', endingDate: '' })
    const [emptyDate, setEmptyDate] = useState(false)
    const [wrongDate, setWrongDate] = useState(false)


    const url = `${baseUrl}competition/addcompetitiondate/${competitionId}`

    const handleDateSetting = (e) => {
        setDate({ ...date, [e.target.name]: e.target.value })
    }

    const saveCompetitionDate = async ({ competitionDate }) => {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': userId
            },
            body: JSON.stringify(competitionDate)
        })

        const data = await response.json()
        console.log(data)
    }

    const handleSubmitDate = () => {

        if (date.startingDate === '' || date.endingDate === '') {
            setEmptyDate(true)
            return
        }

        const startingDate = new Date(date.startingDate)
        const endingDate = new Date(date.endingDate)

        if (startingDate > endingDate) {
            setWrongDate(true)
            return
        }

        const competitionDate = {
            competitionId: competitionId,
            startingDate: startingDate,
            endingDate: endingDate
        }

        saveCompetitionDate({ competitionDate })
    }

    return (
        <div className='p-2'>
            <div className="flex gap-1 mb-3">
                <FaCalendarAlt className="text-slate-600 text-xl" />
                <p className="mt-0.5">{t('Date')}</p>
            </div>
            <div className='flex flex-row gap-4'>
                <div className=''>
                    <p>{t('Starting date')}:</p>
                    <input
                        className="mb-6 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg p-2 mt-1"
                        type='date'
                        name='startingDate'
                        min={today}
                        onChange={(e) => handleDateSetting(e)}
                    />
                </div>
                <div>
                    <p>{t('Ending date')}:</p>
                    <input
                        className="mb-6 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg p-2 mt-1"
                        type='date' name='endingDate'
                        min={today}
                        onChange={(e) => handleDateSetting(e)}
                    />
                </div>
            </div>
            {emptyDate && <ErrorNotice notice={'Fill all the fields'} />}
            {wrongDate && <ErrorNotice notice={'Wrong date'} />}

            <WarningNotice notice={'Date warning'} />

            <button
                className="border-2 rounded py-2 px-3 bg-green-500 hover:bg-green-500 font-bold text-white mt-5"
                onClick={() => handleSubmitDate()}
            >
                {t('Set Date')}
            </button>

        </div>

    );
}

export default SaveCompetitionDate;