import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { FaUsers } from "react-icons/fa";

const MyStaffButton = () => {

    const { t } = useTranslation()

    const navigate = useNavigate()

    const navigateToStaff = () => {

        navigate(`/staff`)
    }

    return (
        <button 
            className="flex py-2 px-3 gap-2 text-slate-500 hover:text-slate-700" 
            onClick={() => navigateToStaff()}
        >
            <div className="mt-1">
                <FaUsers />
            </div>
            <div>{t('Staff')}</div>
        </button>
    );
};

export default MyStaffButton;