import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import UserNaVBar from "../views/user/UserNavBar";

import handleAuthentication from "../hooks/useHandleAuthentication"

import CreateStaff from "../features/staff/CreateStaff";
import ReturnButton from "../navigation/ReturnButton";

import Loading from "../components/icon/Loading";

import { FaUsers } from "react-icons/fa";


const Staff = () => {

    const { t } = useTranslation();

    const user = handleAuthentication()
    const [userId, setUserId] = useState(null)

    useEffect(() => {
        if (user) {
            setUserId(user.uid)
        }
    }, [user])

    
    return (
        <div className="">
        {user ?
            <div>                    
                <UserNaVBar />         
                <div className="p-3 flex justify-between">
                    <div className="ml-2 text-xl text-slate-600 flex"> 
                        <FaUsers className="mr-2 mt-1" /> 
                        <h1>{t('Staff')}</h1>                     
                    </div>   
                    <ReturnButton />                     
                </div>       
                <div className="w-full bg-slate-300 pb-0.5"></div>
                
                <div>                   
                    <CreateStaff userId={userId} />
                </div>            
            </div>
            :
           <Loading />
        }
    </div>
    );
};

export default Staff;