import { useTranslation } from "react-i18next";

import { baseUrl } from '../../urls';

const ParticipantStatusCheckBox = ({ participant, isParticipantOutOfCompetition }) => {
    
    const { t } = useTranslation();

   
    const url = `${baseUrl}participants/changeparticipantstatus`

    
    const changeParticipantStatus = async(value) => {       
        const response = await fetch(url, {
            method: 'PUT',
            headers: { 
                "Content-Type": "application/json",
                "Token": localStorage.getItem("userId") 
            },
            body: JSON.stringify({ participantId: participant.id ,status: value })
        })
        
        const data = await response.json();

        console.log(data);
    }

    const handleCLick = () => {
        changeParticipantStatus(!isParticipantOutOfCompetition);
    }

    return (
        <div className="mt-1">            
            <input 
                className="mt-1 accent-red-500" 
                type="checkbox" id={participant.id} 
                value={!isParticipantOutOfCompetition} 
                onChange={() => handleCLick()} checked={isParticipantOutOfCompetition} 
            />
            <label className="text-slate-100"> {t('Retired')}</label>
        </div>
    )
}

export default ParticipantStatusCheckBox;