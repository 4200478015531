import { FaMale } from "react-icons/fa";
import { FaBicycle } from "react-icons/fa";
import { FaCarSide } from "react-icons/fa";
import { FaAmbulance } from "react-icons/fa";
import { FaLifeRing } from "react-icons/fa";
import { FaBinoculars } from "react-icons/fa";
import { FaShip } from "react-icons/fa";

const RoleIcons = ({ role }) => {
    return (
        <div>
            {role === 'person' &&
                <div className="bg-emerald-500 rounded text-white text-xl p-2 flex justify-center">
                    <FaMale />
                </div>
            }
            {role === 'bicycle' &&
                <div className="bg-lime-500 rounded text-white text-xl p-2 flex justify-center">
                    <FaBicycle />
                </div>
            }
            {role === 'car' &&
                <div className="bg-fuchsia-500 rounded text-white text-xl p-2 flex justify-center">
                    <FaCarSide />
                </div>
            }
            {role === 'ambulance' &&
                <div className="bg-blue-500 rounded text-white text-xl p-2 flex justify-center">
                    <FaAmbulance />
                </div>
            }
            {role === 'lifeguard' &&
                <div className="bg-orange-500 rounded text-white text-xl p-2 flex justify-center">
                    <FaLifeRing />
                </div>
            }
            {role === 'surveillance' &&
                <div className="bg-violet-500 rounded text-white text-xl p-2 flex justify-center">
                    <FaBinoculars />
                </div>
            }
            {role === 'boat' &&
                <div className="bg-amber-500 rounded text-white text-xl p-2 flex justify-center">
                    <FaShip />
                </div>
            }
        </div>
    )
};

export default RoleIcons;