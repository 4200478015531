import { useEffect, useState } from "react";
import { onSnapshot, doc } from "firebase/firestore";

import { db } from "../firebase";

/**
 * The function `useGetParticipantIsOutOfCompetition` is a custom React hook that retrieves the
 * `isOutOfCompetition` value for a given participant from a Firestore database and updates it in
 * real-time.
 * @param participantId - The `participantId` parameter is the unique identifier of the participant
 * whose competition status we want to track.
 * @returns The function `useGetParticipantIsOutOfCompetition` returns the value of the
 * `isOutOfCompetition` state variable.
 */
const useGetParticipantIsOutOfCompetition = (participantId) => {
    const [isOutOfCompetition, setIsOutOfCompetition] = useState(false);

    useEffect(() => {        

        const unsubscribe = onSnapshot(doc(db, 'participant', participantId), (doc) => {
          setIsOutOfCompetition(doc.data().out_of_competition);
        });

        return () => unsubscribe();
    }, [participantId]);

    return isOutOfCompetition;
}

export default useGetParticipantIsOutOfCompetition;