import { useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmDeleteWindow from "../../windows/ConfirmDeleteWindow";

import { FaTrashAlt } from "react-icons/fa"

import { baseUrl } from '../../urls';

const DeleteCompetitionButton = ({ competitionId }) => {
    
    const { t } = useTranslation()

    const url = `${baseUrl}competition/delete`
    const [showConfirmDeleteWindow, setShowConfirmDeleteWindow] = useState(false)
        
    const deleteCompetition = async(competitionId) => {
        const response = await fetch(url , {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json", 
                "Token": localStorage.getItem("userId")               
            },
            body: JSON.stringify({competitionId})
        })        

        console.log(response)
    
    }

    const handleDeleteCompetition = () => {   
        setShowConfirmDeleteWindow(!showConfirmDeleteWindow)     
        deleteCompetition(competitionId)
    }

    return(
        <div className="">
            <button className="border-2 p-1 border-2 border-slate-200 rounded" onClick={() => setShowConfirmDeleteWindow(!showConfirmDeleteWindow)}>
                <FaTrashAlt  className="text-xl text-slate-200 hover:text-red-400"/>                
            </button>
            {showConfirmDeleteWindow &&
                <ConfirmDeleteWindow deleteItem={handleDeleteCompetition} closeWindow={() => setShowConfirmDeleteWindow(!showConfirmDeleteWindow)} text={t('Delete competition text')} />
            }
        </div>
    )
}

export default DeleteCompetitionButton ;