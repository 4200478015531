import { useState } from "react";

import { useTranslation } from "react-i18next";

import AddStaffToCompetition from "../../features/staff/AddStaffToCompetition";

import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";


const CompetitionStaff = ({ competitionId, userId }) => {

    const { t } = useTranslation()

    const [showCompetitionStaff, setShowCompetitionStaff] = useState(false);

    return(
        <div className="mt-5 text-sm md:text-base ml-2 mb-12">
            <div
                className="p-2 flex gap-2 text-slate-700 hover:cursor-pointer hover:text-slate-800"
                onClick={() => setShowCompetitionStaff(!showCompetitionStaff)}
            >                
                {t('Staff')}<span className="text-slate-500">({t('optional')})</span>
                {showCompetitionStaff ?
                    <FaChevronDown />
                    :
                    <FaChevronUp />
                }
            </div>
            {showCompetitionStaff &&
                <AddStaffToCompetition competitionId={competitionId} userId={userId} />
            }
        </div>
    )
};

export default CompetitionStaff;