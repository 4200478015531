import { FaArrowLeft } from "react-icons/fa";

import { useNavigate } from "react-router-dom";

const ReturnButton = () => {

    const navigate = useNavigate();

    return (
        <button className="bg-slate-800 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded-full"        
        onClick={() => {
            navigate(-1);
        }}
        >
            <FaArrowLeft />
        </button>
    );
};

export default ReturnButton;