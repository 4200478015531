import { useTranslation } from 'react-i18next';
import { FaExclamationCircle } from 'react-icons/fa';

const WarningNotice = ({notice}) => {

    const { t } = useTranslation()

    return (
        <p className='text-sm text-slate-700 flex gap-1 my-2'>
            <span className='text-orange-500 mt-0.5'>
                <FaExclamationCircle />
            </span>
            {t(notice)}
        </p>
    )
}

export default WarningNotice