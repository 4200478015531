import UserNaVBar from "../views/user/UserNavBar"
import Loading from "../components/icon/Loading"
import CreateCompetition from "../features/competition/CreateCompetition"
import CompetitionsList from "../views/competition/CompetitionList"
import HandleAuthentication from "../hooks/useHandleAuthentication"

const Home = () => {

    const user = HandleAuthentication()    

    return (
        <div>
            {user ?
                <div>
                    <UserNaVBar />
                    <div className="mt-5">
                        <CreateCompetition userId={user.uid}/>
                        <CompetitionsList  userId={user.uid}/>
                        
                    </div>                    
                </div>
                :
                <div>
                    <Loading />
                </div>

            }
        </div>
    )
}

export default Home