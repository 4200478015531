import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAuth, updatePassword, reauthenticateWithCredential } from 'firebase/auth';
import { EmailAuthProvider } from "firebase/auth";

import ErrorMessageWindow from '../../windows/ErrorMessageWindow';
import LoadingIconGreen from '../../components/icon/LoadingIconGreen';

import { FaChevronUp } from "react-icons/fa"
import { FaChevronDown } from "react-icons/fa"

const ChangePassword = () => {
    const { t } = useTranslation()

    const [showSetPassword, setShowSetPassword] = useState(false)
    const [currentPassword, setCurrentPassword] = useState('') // TODO: Add current password check [https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [passwordsMatch, setPasswordsMatch] = useState(false)
    const [textPasswordMatch, setTextPasswordMatch] = useState(true)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isChangingPassword, setIsChangingPassword] = useState(false)


    const auth = getAuth();
    const user = auth.currentUser;   

    useEffect(() => {
        if (newPassword !== '' && confirmNewPassword !== '' && currentPassword !== '') {
            if (newPassword !== confirmNewPassword) {
                setPasswordsMatch(false)
                setTextPasswordMatch(false)
            }
            else {
                setPasswordsMatch(true)
                setTextPasswordMatch(true)
            }
        } else {
            setPasswordsMatch(false)
            setTextPasswordMatch(true)
        }
    }, [newPassword, confirmNewPassword, currentPassword])

    const handleChangePassword = async () => {

        setIsChangingPassword(true)

        const credential = EmailAuthProvider.credential(user.email, currentPassword);
        
        try {

            await reauthenticateWithCredential(user, credential)
            await updatePassword(user, newPassword)
            setIsChangingPassword(false)
            setErrorMessage(t('Password changed successfully'))
            setShowErrorMessage(true)

        } catch (error) {
            
            setIsChangingPassword(false)
            setErrorMessage(error.message)
            setShowErrorMessage(true)
            return
        }    
        
        setIsChangingPassword(false)
    }


    return (
        <div className="mt-5 text-sm md:text-base ml-2">
            <div className="p-2 flex gap-2 text-slate-700 hover:cursor-pointer hover:text-slate-800" onClick={() => setShowSetPassword(!showSetPassword)}>
                {t('Password')}
                {showSetPassword ?
                    <FaChevronDown />
                    :
                    <FaChevronUp />
                }
            </div>
            {showErrorMessage &&
                <ErrorMessageWindow closeWindow={() => setShowErrorMessage(false)} text={errorMessage} />
            }
            {showSetPassword &&
                <div className='p-4'>
                    <div className="my-2">
                        <input
                            type="password"
                            name="currentPassword"
                            id="currentPassword"
                            className="border border-gray-300 rounded-md p-1"
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            placeholder={t('Current password')}
                        />
                    </div>
                    <div className="my-2">
                        <input
                            type="password"
                            name="newPassword"
                            id="newPassword"
                            className="border border-gray-300 rounded-md p-1"
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder={t('New password')}
                        />
                    </div>
                    <div className="my-2">
                        <input
                            type="password"
                            name="confirmNewPassword"
                            id="confirmNewPassword"
                            className="border border-gray-300 rounded-md p-1"
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            placeholder={t('Confirm password')}
                        />
                    </div>
                    <div className='my-2'>
                        {!textPasswordMatch && <p className="text-red-500 text-sm">{t('Passwords do not match')}</p>}
                    </div>
                    <div className='mt-5'>
                        {passwordsMatch & !isChangingPassword ?
                            <button
                                className="bg-green-500 text-white rounded-md px-2 py-1 hover:bg-green-600"
                                onClick={() => handleChangePassword()}
                            >{t('Change password')}</button>
                            :

                            <button className="bg-slate-500 text-white rounded-md px-2 py-1 " disabled>{t('Change password')}</button>
                        }
                        {isChangingPassword &&
                            <div className="flex items-center gap-2">
                                <LoadingIconGreen />
                            </div>
                        }
                    </div>
                </div>
            }
        </div>

    )
};

export default ChangePassword;