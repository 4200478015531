import { useState } from "react";
import { useTranslation } from "react-i18next";

import { baseUrl } from '../../urls';

const CreateCompetition = ({ userId }) => {

    const { t } = useTranslation();

    const [competitionName, setCompetitionName] = useState('');
    const [invalidCompetitionName, setInvalidCompetitionName] = useState(false);
    const url = `${baseUrl}competition/add`;

    const checkIfCompetitionisEmptyString = (competitionName) => {
        if (competitionName === '') {
            return true;
        } else {
            return false;
        }
    }

    const createCompetition = async (eventId, competitionName) => {

        if (checkIfCompetitionisEmptyString(competitionName)) {
            setInvalidCompetitionName(true);
            return;
        } else {
            setInvalidCompetitionName(false);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': localStorage.getItem('userId')
                },
                body: JSON.stringify({ userId, competitionName })
            });

            const data = await response.json();

            console.log(data);
        }


    }

    const handleCreateCompetition = () => {
        try {
            createCompetition(userId, competitionName)
            setCompetitionName('');
        } catch (error) {
            //handle error
            console.log(error);
        }
    }

    return (
        <div>
            <p className="text-center mt-12 text-xl text-slate-500">{t('Add Competition')}:</p>
            <div className="mt-2 ml-2 flex justify-center mt-6">
                <input 
                    className="w-5/6 sm:w-3/6 md:w-2/6 lg:w-2/6 xl:w-1/6 bg-slate-100 border border-slate-300 text-gray-900 text-sm p-2 rounded" 
                    type="text" 
                    placeholder={t('Competition name')} 
                    value={competitionName} 
                    onChange={(e) => setCompetitionName(e.target.value)} 
                />
                <button 
                    className="border-2 rounded py-1 px-4 ml-2 bg-green-400 text-white hover:bg-green-500 text-xl" 
                    onClick={() => handleCreateCompetition()}
                >
                    +
                </button>
            </div>
            {invalidCompetitionName && <p className="text-red-500 text-sm ml-4 mt-5 text-center">{t('Competition name is required')}</p>}
        </div>
    )
}

export default CreateCompetition;