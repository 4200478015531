import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

const getCompetitionTrack = async (competitionId) => {
    const eventsRef = collection(db, "track");
    const q = query(eventsRef, where("competition_id", "==", competitionId));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
        return null
    }
    const track = querySnapshot.docs[0].data()
    return track
}

export default getCompetitionTrack;