import { baseUrl } from "../../urls";

import { FaTrash } from "react-icons/fa";

const DeleteStaffButton = ({ staffId }) => {

    const url = `${baseUrl}staff/delete`;

    const deleteStaff = async () => {

        const res = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('userId')
            },
            body: JSON.stringify({staffId}),
        })

        console.log(res)
        
    };

    const handleDeleteStaff = () => {
        deleteStaff()
    };

    return (
        <button className="hover:text-red-500">
            <FaTrash onClick={handleDeleteStaff} />
        </button>
    )

};

export default DeleteStaffButton;