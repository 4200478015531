const getCompetitonIdFromPath = (path) => {
    
    let competitionId;

    if (!path) throw new Error("No path provided");

    competitionId = path.split("/")[3];

    return competitionId;
}

export default getCompetitonIdFromPath;