import { useEffect, useState } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import * as turf from "@turf/turf";
import "leaflet/dist/leaflet.css";

import AddWaypointsToTrack from "../../features/waypoint/AddWaypointsToTrack";
import DeleteCompetitionTrackButton from "../../features/track/DeleteCompetitionTrackButton"
import WaypointMarker from "../marker/WaypointMarker";
import WaypointsTable from "../../views/waypoint/WaypointsTable";

import useGetTrackWaypoints from "../../hooks/useGetTrackWaypoints";

const MapTrackSetting = ({ competitionTrack }) => {

    const geoJson = JSON.parse(competitionTrack.data()["competition_track"])
    const [center, setCenter] = useState()
    const competitionId = competitionTrack.data()["competition_id"]
    const [movingWaypoint, setMovingWaypoint] = useState()
    const waypoints = useGetTrackWaypoints(competitionId)

    const handleMovingWaypoint = (point) => {
        setMovingWaypoint(point)
    }

    useEffect(() => {
        const mapCenter = turf.centerOfMass(geoJson)
        setCenter([mapCenter.geometry.coordinates[1], mapCenter.geometry.coordinates[0]])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    
    return (
        <div className="w-full md:w-1/2 h-1/4" >
            {center &&
                <div className="p-2">
                    <MapContainer center={center} zoom={13} scrollWheelZoom={false} className="h-64 md:h-96 rounded" attributionControl={false}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            className='map-tiles'
                        />
                        <GeoJSON data={geoJson.features[0].geometry} style={{ color: '#06b6d4', weight: '5' }}/> {/*Only renders the linestring */}
                                             
                        {movingWaypoint &&
                            <WaypointMarker waypoint={movingWaypoint} />
                        }
                        {waypoints && waypoints.map((waypoint, index) => {
                            return <WaypointMarker key={index} waypoint={waypoint} />
                        })}  
                    </MapContainer>
                    <AddWaypointsToTrack track={competitionTrack} setMovingWayPoint={ handleMovingWaypoint }/>
                    <WaypointsTable trackId={competitionTrack.id} waypoints={waypoints} />        
                    <DeleteCompetitionTrackButton competitionId={ competitionId } />
                </div>
            }

        </div>
    )
}

export default MapTrackSetting