import { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

const useStaffChangesListener = (staffId) => {
    
        const [data, setData] = useState([])
    
        useEffect(() => {    
            
            const unsubscribe = onSnapshot(doc(db, 'staff', staffId), (doc) => {
                const data = doc.data()
                setData(data)
            });               
    
            return () => unsubscribe()
    
        }, [staffId])
        
        return data
} 

export default useStaffChangesListener