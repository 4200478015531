import { useTranslation } from "react-i18next";

import { FaExclamationTriangle} from "react-icons/fa"

const ErrorNotice = ({ notice }) => {

    const { t } = useTranslation()

    return (
        <p className="text-red-500 mb-2 text-sm flex gap-1">
            <span className='mt-0.5'>
                <FaExclamationTriangle/>
            </span>
            {t(notice)}
        </p>
    )

}

export default ErrorNotice