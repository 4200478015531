import { useState, useEffect } from "react"
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

/**
 * The function `useGetCompetitionDate` is a custom React hook that retrieves the starting and ending
 * dates of a competition from the Firestore database based on the provided competition ID.
 * @param competitionId - The competitionId parameter is the unique identifier for a specific
 * competition. It is used to fetch the competition data from the database.
 * @returns The function `useGetCompetitionDate` returns the `competitionDate` object, which contains
 * the starting and ending dates of a competition.
 */
const useGetCompetitionDate = (competitionId) => {
    const [competitionDate, setCompetitionDate] = useState({ startingDate: '', endingDate: '' })

    useEffect(() => {
        
        const unsubscribe = onSnapshot(doc(db, 'competition', competitionId), (doc) => {
            
            if(doc.data().starting_date === undefined) {
                setCompetitionDate({})
            } else {
                setCompetitionDate({ startingDate: doc.data().starting_date, endingDate: doc.data().ending_date }) 
            }      
        })

        return () => unsubscribe()

    }, [competitionId])

    return competitionDate

}

export default useGetCompetitionDate

