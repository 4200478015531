import { useTranslation } from 'react-i18next';

const ConfirmDeleteWindow = ({ deleteItem, closeWindow, text }) => {

    const { t } = useTranslation();

    return (
        <div className="absolute bg-slate-400 border border-slate-200 text-center rounded shadow p-4 text-sm md:text-base top-1/2 left-1/2 md:transform -translate-x-1/2 -translate-y-1/2" style={{ zIndex: '600' }}>
            <div className="">
                <h2 className="mt-2 text-slate-100">{text}</h2>
                <div className="flex justify-around w-1/2 mx-auto mt-3 gap-2">
                    <button className="rounded p-2 bg-slate-500 hover:bg-slate-600 text-white" onClick={closeWindow}>
                        {t('Cancel')}
                    </button>
                    <button className="rounded p-2 border-red-200 bg-red-400 hover:bg-red-500 text-white" onClick={deleteItem}>
                        {t('Delete')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDeleteWindow;