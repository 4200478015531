import { useState, useEffect } from "react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

/**
 * The function `useAlertsDataListener` is a custom hook that listens for changes in a
 * Firestore collection and returns the alerts data for a specific competition ID.
 * @param competitionId - The competitionId parameter is the ID of the competition for which we want to
 * listen for alerts.
 * @returns The `alerts` array is being returned.
 */
const useAlertsDataListener = (competitionId) => {
    
    const [alerts, setAlerts] = useState([])

    useEffect(() => {
            
        const alertsRef = collection(db, "alert");
        const q = query(alertsRef, where("competition_id", "==", competitionId));
        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const alerts = [];
            querySnapshot.forEach((doc) => {
                alerts.push({"id": doc.id, "data": doc.data()});
            })    
            
            setAlerts(alerts)
        });
        
        return () => unsubscribe()
        
    }, [competitionId])

    return alerts
}

export default useAlertsDataListener;