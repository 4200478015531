import { useTranslation } from "react-i18next"

import CompetitionBox from "../../components/box/CompetitionBox";
import useCompetitionDataListener from "../../hooks/useCompetitionDataListener";



const CompetitionsList = ({ userId }) => {

    const { t } = useTranslation();

    const competitions = useCompetitionDataListener(userId)

    return (
        <div>                                 
                
                {(competitions.length > 0) ?
                    (   
                        <div className="grid grid-cols-1 md:w-2/3 lg:w-2/3 xl:w-1/3 mx-auto gap-4 xl:gap-16 justify-center mt-8 p-2 lg:p-4">
                            {competitions.map((competition) => (
                                <CompetitionBox key={competition.id} competition={competition} />
                            ))}
                                  
                        </div>
                    )

                    :
                    <div className="text-center mt-20 text-xl md:text-2xl">                
                      <p className="my-3 text-slate-400">{t('There are no competitions...')}</p>
                    </div>
                }
                
            </div>
                 
        
    )
}

export default CompetitionsList;
