import { useState } from "react"
import { useTranslation } from "react-i18next"

import SaveCompetitionDate from "../../features/date/SaveCompetitionDate"
import SaveCompetitionTime from "../../features/date/SaveCompetitionTime"
import WarningNotice from "../../components/notice/WarningNotice"

import useGetCompetitionDate from "../../hooks/useGetCompetitionDate"

import dateToString from "../../utils/dateToString"

import { FaChevronUp } from "react-icons/fa"
import { FaChevronDown } from "react-icons/fa"
import { FaCalendarAlt } from "react-icons/fa"


const CompetitionDate = ({ competitionId }) => {

    const { t } = useTranslation()

    const competitionDate = useGetCompetitionDate(competitionId)

    const [showCompetitionDate, setShowCompetitionDate] = useState(false)    
 

    return (
        <div className="mt-5 text-sm md:text-base ml-2">
            <div
                className="p-2 flex gap-2 text-slate-700 hover:cursor-pointer hover:text-slate-800"
                onClick={() => setShowCompetitionDate(!showCompetitionDate)}
            >

                {t('Competition date')}
                {showCompetitionDate ?
                    <FaChevronDown />
                    :
                    <FaChevronUp />
                }

            </div>
            {showCompetitionDate &&
                <div className="p-4">
                    {competitionDate.startingDate === undefined && competitionDate.endingDate === undefined

                        ?

                        <SaveCompetitionDate competitionId={competitionId} />

                        :

                        <div className="text-slate-600">
                            <div className="flex gap-1 mb-3">
                                <FaCalendarAlt className="text-slate-600 text-xl" />
                                <p className="mt-0.5">{t('Date')}</p>
                            </div>
                           
                            <div className="flex flex-row w-full gap-10 mb-5">                                
                                <div>
                                    <p> {t('Starting date')}:&nbsp;</p>
                                    <div className="bg-slate-800 p-3 rounded mt-1">                                   
                                        <span className="text-white">
                                            {dateToString(competitionDate.startingDate)}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <p>{t('Ending date')}:&nbsp;</p>
                                    <div className="bg-slate-800 p-3 rounded mt-1">                                        
                                        <span className="text-white">
                                            {dateToString(competitionDate.endingDate)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <WarningNotice notice={'Date warning'} /> 
                            <SaveCompetitionTime competitionId={competitionId} competitionDate={competitionDate} />                          
                        </div>
                    }
                </div>
            }
        </div>
    )

}

export default CompetitionDate