import { useTranslation } from "react-i18next";

import UserNaVBar from "../views/user/UserNavBar"
import Loading from "../components/icon/Loading"
import ReturnButton from "../navigation/ReturnButton"

import HandleAuthentication from "../hooks/useHandleAuthentication"

import LanguageSetting from "../features/language/LanguageSetting";
import ChangePassword from "../features/user/ChangePassword";

import { FaCog } from "react-icons/fa";

const Settings = () => {
    
    const { t } = useTranslation()

    const user = HandleAuthentication()

    return (
        <div>
            {user ?
                <div>
                    <UserNaVBar />
                    <div className="p-3 flex justify-between">
                        <div className="ml-2 text-xl text-slate-600 flex"> 
                            <FaCog className="mr-2 mt-1" />                             
                            <p>{t('Settings')}</p>                            
                        </div>  
                        <ReturnButton />                      
                    </div>       
                    <div className="w-full bg-slate-300 pb-0.5"></div>       
                    <div>
                        <LanguageSetting />
                    </div>  
                    <div>
                        <ChangePassword />
                    </div>          
                </div>
                :
                <div>
                    <Loading />
                </div>

            }
        </div>
    )
    
}

export default Settings;