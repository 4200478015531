import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import * as turf from "@turf/turf";

import getCompetitionTrack from "../../services/getCompetitionTrack";


const MapMiniature = ({ competitionId }) => {

    const { t } = useTranslation();
    
    const [geoJson, setGeoJson] = useState(null)
    const [center, setCenter] = useState(null)

    useMemo(() => {
        const getTrack = async () => {
            const track = await getCompetitionTrack(competitionId)
            if (!track) {
                return
            }
            const geoJson = JSON.parse(track['competition_track'])
            setGeoJson(geoJson)
        }
        getTrack()
    }, [competitionId])

    useEffect(() => {
        if (geoJson) {
            const mapCenter = turf.centerOfMass(geoJson)
            setCenter([mapCenter.geometry.coordinates[1], mapCenter.geometry.coordinates[0]])
        }
    }, [geoJson])

    return (
        <div className="border border-2 border-slate-200 rounded w-52 h-52  bg-slate-600" >
            {geoJson && center
                ?
                <MapContainer 
                    center={center} 
                    zoom={10} 
                    zoomControl={false} 
                    scrollWheelZoom={false} 
                    dragging={false}
                    attributionControl={false}
                    style={{ height: "204px", width: "204px" }}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        className = 'map-tiles'
                    />
                    <GeoJSON data={geoJson.features[0].geometry} style={{ color: '#06b6d4'}}/>
                </MapContainer>
                :
                <div className="text-center mt-20 text-xl md:text-2xl0">
                    <p className="my-3 text-slate-300">{t('No track added')}</p>
                </div>
            }

        </div>
    )
};

export default MapMiniature;