import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from '@firebase/firestore';
import { db } from '../firebase';

const useGetParticipants = (competitionId) => {
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        const participantsRef = collection(db, 'participant');
        const q = query(participantsRef, where('competition_id', '==', competitionId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const participants = querySnapshot.docs.map((doc) => {
                return {
                    id: doc.id,
                    data: doc.data(),
                };
            });

            const removedKmInOutOfCompetitionParticipants = participants.map((participant) => {
                if (participant.data.out_of_competition) {
                    return {
                        ...participant,
                        data: {
                            ...participant.data,
                            km: -2,
                        }
                    }
                }

                if (participant.data.ended) {
                    return {
                        ...participant,
                        data: {
                            ...participant.data,
                            km: -1,
                        }
                    }
                }

                return participant;
            });


            const sortedParticipants =removedKmInOutOfCompetitionParticipants.sort((a, b) => {
                return b.data.km - a.data.km;
            });

            const participantsWithPosition = sortedParticipants.map((participant, index) => {
                return {
                    ...participant,
                    position: index + 1,
                }
            });
            
            
            setParticipants([...participantsWithPosition]);
        });


        return () => unsubscribe();
    }, [competitionId]);

    return participants;
}

export default useGetParticipants;