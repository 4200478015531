// converts the participants object to an array of objects that can be saved to the database

export const transformParticipantsObjectToSave = (start, end, competitionId) => {
    let participantsArray = []

    for (let i = start; i <= end; i++) {
        participantsArray.push({
            name:"",
            last_name: "",
            bib_number: parseInt(i),
            phone_number: "",
            competition_id: competitionId,
            out_of_competition: false,
            km: 0,
            gender: "",
            connected: false,
            ended: false,
        })
    }    
    
    return participantsArray
}

export const transformParticipantDataToSave = (participantData) => {
    let participantsArray = []

    participantData.bibNumber.forEach((participant, index) => {
        participantsArray.push({
            name: participantData.name[index] ? participantData.name[index] : "",
            lastName: participantData.lastName[index] ? participantData.lastName[index] : "",
            bibNumber: participantData.bibNumber[index] ? parseInt(participantData.bibNumber[index]) : 0,
            phoneNumber: participantData.phoneNumber[index] ? participantData.phoneNumber[index] : "",        
            gender: participantData.gender[index] ? participantData.gender[index] : "M",               
        })
    })

    return participantsArray
};
