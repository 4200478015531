import {  useState } from "react";

import WaypointCounter from "./WaypointCounter";
import WaypointList from "./WaypointList";


const WayPointsMapPanel = ({ waypoints, participants }) => {

    const [showCheckedParticipants, setShowCheckedParticipants] = useState(false)
    const [waypoint, setWaypoint] = useState()

    const setWaypointAndShow = (waypoint) => {
        setWaypoint(waypoint)
        setShowCheckedParticipants(!showCheckedParticipants)
    }   

    const closeWaypointList = () => {
        setShowCheckedParticipants(false)
    }

    return (
        <div>
            <div>
                {waypoints.length > 0 &&
                    <div className="bg-slate-800 rounded px-2 py-0.5 gap-6 mt-3 cursor-default text-xs">
                        {waypoints.map((waypoint, index) => (
                            <div className="my-2" key={index} >
                                <div className="flex gap-0.5 text-slate-100">
                                    <p key={index} className="">Km: {waypoint.data.km}</p>
                                </div>
                                <div                                              
                                    className="cursor-pointer"                       
                                    onClick={() => setWaypointAndShow(waypoint)}                                                      
                                >
                                    <WaypointCounter waypoint={waypoint} />
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
            {showCheckedParticipants &&
                <WaypointList waypoint={waypoint} participants={participants} close={closeWaypointList} />
            }
        </div>

    )
}

export default WayPointsMapPanel;