import RoleIcon from '../../components/icon/RoleIcons';
import RemoverStaffFromCompetitionButton from '../../features/staff/RemoveStaffFromCompetitionButton';

const CompetitionStaffBox = ({ staff, competitionId }) => {

    return (
        <div className='grid grid-cols-5 text-slate-200 md:w-1/2 my-4 bg-slate-700 py-2 rounded'>
            <div className='mx-auto'>
                <RoleIcon role={staff.role} />
            </div>
            <div>
                <p className='mt-2'>{staff.name}</p>
            </div>
            <div>
                <p className='mt-2 text-center'>{staff.phone_number}</p>
            </div>
            <div>
                <a href={staff.qr_url} target='_blank' rel="noreferrer" className='mt-2'>
                    <p className='mt-2 underline text-center'>QR</p>
                </a>
            </div>
            <div className='mx-auto'>
                <RemoverStaffFromCompetitionButton competitionId={competitionId} staffId={staff.id} />
            </div>
        </div>
    )
};

export default CompetitionStaffBox;