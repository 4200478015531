import { useEffect, useState } from "react";


import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import * as turf from "@turf/turf";
import "leaflet/dist/leaflet.css";


import ReturnButton from "../../navigation/ReturnButton";
import ParticipantMarker from "../marker/ParticipantMarker";
import StaffMarker from "../marker/StaffMarker";
import WaypointCompetitionMarker from "../marker/WaypointCompetitionMarker";
import WayPointsMapPanel from "../../views/waypoint/WaypointsMapPanel";

import getCompetitionTrack from "../../services/getCompetitionTrack";
import getCompetitionWaypoints from "../../services/getCompetitionWaypoints";


import { FaCheck } from "react-icons/fa";


const MapCompetitionView = ({ competitionId, participants, alerts, exceededTimeParticipants, staff }) => {

    const [geoJson, setGeoJson] = useState()
    const [waypoints, setWaypoints] = useState()
    const [center, setCenter] = useState()
    
    const [showStaff, setShowStaff] = useState(true)

    useEffect(() => {
        const getTrack = async () => {
            const track = await getCompetitionTrack(competitionId)
            const geoJson = JSON.parse(track['competition_track'])
            setGeoJson(geoJson)
        }
        getTrack()
    }, [competitionId])


    useEffect(() => {
        if (geoJson) {
            const mapCenter = turf.centerOfMass(geoJson)
            setCenter([mapCenter.geometry.coordinates[1], mapCenter.geometry.coordinates[0]])
        }
    }, [geoJson])

    useEffect(() => {
        const getWaypoints = async () => {
            const waypoints = await getCompetitionWaypoints(competitionId)
            waypoints.sort((a, b) => Math.floor(a.data.km) - Math.floor(b.data.km))
            setWaypoints(waypoints)
        }
        getWaypoints()
    }, [competitionId])

    return (
        <div>

            {center && geoJson &&
                <MapContainer
                    center={center}
                    zoom={14}
                    minZoom={10}
                    zoomControl={false}
                    scrollWheelZoom={true}
                    style={{ height: "100vh" }}
                    trackResize={false}
                    attributionControl={false}
                >

                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        className='map-tiles'
                    />

                    <GeoJSON data={geoJson.features[0].geometry} style={{ color: '#06b6d4', weight: '5' }} /> {/*Only renders the linestring */}

                    {participants.map(participant => (
                        <ParticipantMarker
                            key={participant.id}
                            participantData={participant}
                            alerts={alerts}
                            exceededTimeParticipants={exceededTimeParticipants}
                        />
                    ))}
                    {showStaff &&
                        <div>
                            {staff.map(staffMember => (
                                <StaffMarker key={staffMember.id} staffMember={staffMember} />
                            ))}
                        </div>
                    }

                    {waypoints !== undefined && waypoints.map((waypoint, index) => (
                        <WaypointCompetitionMarker key={index} waypoint={waypoint} inCompetition={true} />
                    ))}


                    <div className="w-1/8 fixed top-1 p-2" style={{ zIndex: '400' }}>
                        <ReturnButton />
                        <div
                            className="w-1/8 fixed py-2 hover:cursor-pointer mt-2"
                            style={{ zIndex: '400' }}
                            onClick={() => setShowStaff(!showStaff)}
                        >
                            <div className="px-1 py-2 bg-slate-800 text-white rounded flex gap-1">
                                {showStaff ?
                                    <div className="rounded bg-white px-0.5">
                                        <FaCheck className="text-xs text-slate-700 mt-1" />
                                    </div>
                                    :
                                    <div className="rounded bg-white py-1 px-2">

                                    </div>
                                }
                                <p className="font-bold">Staff</p>
                            </div>
                        </div>
                    </div>
                </MapContainer>
            }
            {/* out of the map to be able to scroll */}
            <div className="w-1/8 fixed top-20 p-2" style={{ zIndex: '400' }}>
                {waypoints &&
                    <WayPointsMapPanel waypoints={waypoints} participants={participants}/>
                }
            </div>
        </div>
    );
};

export default MapCompetitionView;