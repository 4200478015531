import { db } from "../firebase";
import { where, collection, query, getDocs } from "firebase/firestore";

const getParticipantsByCompetitionId = async (competitionId) => {

    try {       

        const participantsRef = collection(db, "participant");  
        const q = query(participantsRef, where("competition_id", "==", competitionId));
        const querySnapshot = await getDocs(q);        

        return querySnapshot.docs.map(doc => ({id: doc.id, data: doc.data()}));
    } catch (error) {
        throw new Error("Error getting participants");
    }
}

export default getParticipantsByCompetitionId;