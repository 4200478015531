import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

const getCompetitionWaypoints = async (competitionId) => {    
     
    const eventsRef = collection(db, "waypoint");
    const q = query(eventsRef, where("competition_id", "==", competitionId));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((doc) => ({'id': doc.id, 'data':doc.data()}));    
    
    return data;
};

export default getCompetitionWaypoints;