import { useState, useEffect } from "react"
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

/**
 * The function `useGetCompetitionTrack` is a custom React hook that retrieves the competition track
 * based on the provided competition ID and returns it.
 * @param competitionId - The `competitionId` parameter is the unique identifier of a competition. It
 * is used to query the database and retrieve the competition track associated with that competition.
 * @returns The `CompetitionTrack` state variable is being returned.
 */
const useGetCompetitionTrack = (competitionId) => {
    const [CompetitionTrack, setCompetitionTrack] = useState(null)

    useEffect(() => {

        const eventsRef = collection(db, "track");
        const q = query(eventsRef, where("competition_id", "==", competitionId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {

            if (querySnapshot.docs[0]) {
                setCompetitionTrack(querySnapshot.docs[0])

            } else {
                setCompetitionTrack(null)
            }
        })

        return () => unsubscribe()


    }, [competitionId])

    return CompetitionTrack

}
export default useGetCompetitionTrack