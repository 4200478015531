import { FaTrashAlt } from "react-icons/fa"

import { baseUrl } from '../../urls';

const DeleteWaypointButton = ({waypointId}) => {
    
    const url= `${baseUrl}waypoint/${waypointId}/delete`
    
    const deleteWaypoint = async () => {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('userId')
            },
        })    
        console.log(response)
        return response
    }

    const handleClick = () => {
        deleteWaypoint()
    }

    return (
        <button className="p-2 mt-1" onClick={() => handleClick()}>
            <FaTrashAlt  className="text-red-400 hover:text-red-500"/>
        </button>
    )
}

export default DeleteWaypointButton;