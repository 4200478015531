import { useState } from "react"
import { useTranslation } from "react-i18next"


import ConfirmDeleteWindow from "../../windows/ConfirmDeleteWindow"

import LoadingIconRed from "../../components/icon/LoadingIconRed"

import { baseUrl } from '../../urls';

const DeleteCompetitionTrackButton = ({ competitionId }) => {

    const { t } = useTranslation()

    const url = `${baseUrl}track/delete/${competitionId}`
    const [showConfirmDeleteWindow, setShowConfirmDeleteWindow] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)


    const deleteCompetitionTrack = async (competitionId) => {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('userId')
            },
        })

        const data = await response.json()

        console.log(data)

    }

    const handleDeleteCompetitionTrack = async () => {
        setIsDeleting(true)
        setShowConfirmDeleteWindow(!showConfirmDeleteWindow)
        await deleteCompetitionTrack(competitionId)

    }

    return (
        <div>


            {isDeleting ?
                <div className="flex justify-center mt-5">
                    <LoadingIconRed />
                </div>
                :
                <button
                    className="text-white py-2 px-3 rounded bg-red-400 hover:bg-red-500 font-bold mt-8"
                    onClick={() => setShowConfirmDeleteWindow(!showConfirmDeleteWindow)}
                >
                    {t('Delete Track')}
                </button>
            }
            {showConfirmDeleteWindow &&
                <div className="relative">
                    <ConfirmDeleteWindow
                        deleteItem={handleDeleteCompetitionTrack}
                        closeWindow={() => setShowConfirmDeleteWindow(!showConfirmDeleteWindow)}
                        text={t('Delete track text')}
                    />
                </div>
            }
        </div>
    )
}

export default DeleteCompetitionTrackButton