import { useState, useEffect } from "react"
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

/**
 * The function `useGetWaypointCheckedParticipants` is a custom hook in JavaScript that retrieves and
 * updates the number of checked participants for a given waypoint ID using Firebase Firestore.
 * @param waypointId - The waypointId parameter is the unique identifier of the waypoint for which you
 * want to retrieve the checked participants.
 * @returns The function `useGetWaypointCheckedParticipants` returns the `waypointCheckedParticipants`
 * state variable.
 */
const useGetWaypointCheckedParticipants = (waypointId) => {

    const [waypointCheckedParticipants, setWaypointCheckedParticipants] = useState([])

    useEffect(() => {

        const unsubscribe = onSnapshot(doc(db, 'waypoint', waypointId), (doc) => {
            
            setWaypointCheckedParticipants(doc.data().participants_checked)
            
        })

        return () => unsubscribe()

    }, [waypointId])    
    return waypointCheckedParticipants


};

export default useGetWaypointCheckedParticipants;