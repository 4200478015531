import { useState } from 'react';
import { useTranslation } from "react-i18next";
import i18n from '../../i18n';

import { FaChevronUp } from "react-icons/fa"
import { FaChevronDown } from "react-icons/fa"

const LanguageSetting = () => {

    const { t } = useTranslation()

    const language = i18n.language

    const [showLanguageSetting, setShowLanguageSetting] = useState(false)



    const changeLanguage = (lng) => {
        localStorage.setItem('i18nextLng', lng)
        i18n.changeLanguage(lng);
    }

    return (
        <div className="mt-5 text-sm md:text-base ml-2">
            <div className="p-2 flex gap-2 text-slate-700 hover:cursor-pointer hover:text-slate-800" onClick={() => setShowLanguageSetting(!showLanguageSetting)}>
                {t('Language')}
                {showLanguageSetting ?
                    <FaChevronDown />
                    :
                    <FaChevronUp />
                }
            </div>
            {showLanguageSetting &&
                <div className="p-4">
                    <div>
                        {language === 'es' ?
                            <div>
                                <button className="border-2 rounded py-1 px-2 border-green-500 text-slate-500" onClick={() => changeLanguage('es')}>
                                    Español
                                </button>
                                <button className="border-2 rounded py-1 px-2 text-slate-500" onClick={() => changeLanguage('en')}>
                                    English
                                </button>
                            </div>
                            :
                            <div>
                                <button className="border-2 rounded py-1 px-2 text-slate-500" onClick={() => changeLanguage('es')}>
                                    Español
                                </button>
                                <button className="border-2 rounded py-1 px-2 border-green-500 text-slate-500" onClick={() => changeLanguage('en')}>                                    
                                    English                                    
                                </button>
                            </div>
                        }

                    </div>
                </div>
            }
        </div>
    )
};

export default LanguageSetting;