import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import ErrorNotice from "../../components/notice/ErrorNotice";

import getCompetitionTime from "../../services/getCompetitionTime";

import { baseUrl } from "../../urls";

import { FaClock } from "react-icons/fa";


const SaveCompetitionTime = ({ competitionId, competitionDate }) => {

    const { t } = useTranslation()

    const userId = localStorage.getItem('userId')

    const [time, setTime] = useState({ startingTime: '', endingTime: '' })
    const [savedTime, setSavedTime] = useState(null)


    const [isTimeSet, setIsTimeSet] = useState(false)
    const [isValidTime, setIsValidTime] = useState(false)
    const [isTimeSaved, setIsTimeSaved] = useState(false)

    const callCompetitionTime = async () => {
        const time = await getCompetitionTime(competitionId)
        if (time === null) return
        setSavedTime(time)
        setTime(time)
    }

    useEffect(() => {
        callCompetitionTime()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [competitionId])

    const saveCompetitionTime = async () => {
        const response = await fetch(`${baseUrl}competition/addcompetitiontime/${competitionId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': userId
            },
            body: JSON.stringify(time)
        })
               
        if (response.status === 200) {
            setIsTimeSaved(true)
        }
    }

    useEffect(() => {

        if (time.startingTime !== '' && time.endingTime !== '') setIsTimeSet(true)

    }, [time])

    useEffect(() => {

        if (time.startingTime === '' || time.endingTime === '') return

        if (time.startingTime === undefined || time.endingTime === undefined) return


        const startingDate = new Date(competitionDate.startingDate)
        const endingDate = new Date(competitionDate.endingDate)

        try {
            startingDate.setHours(time.startingTime.split(':')[0])
            startingDate.setMinutes(time.startingTime.split(':')[1])

            endingDate.setHours(time.endingTime.split(':')[0])
            endingDate.setMinutes(time.endingTime.split(':')[1])
        } catch (error) {
            console.log(error)
        }

        if (startingDate < endingDate) {
            setIsValidTime(true)
        } else {
            setIsValidTime(false)
        }

    }, [isTimeSet, time])


    return (
        <div className="mt-10">
            <div className="flex gap-1 mb-3">
                <FaClock className="text-slate-600 text-xl" />
                <p className="">{t('Time')}</p>
            </div>
            <div>
                {savedTime
                    ?
                    <div className='flex flex-row gap-6 md:gap-12'>
                        <div className=''>
                            <p>{t('Starting time')}:</p>
                            <input
                                className="mb-4 bg-slate-800 text-white dark border border-slate-800 text-gray-900 text-sm rounded p-2 mt-1"
                                type='time'
                                name='startingTime'
                                value={time.startingTime}
                                onChange={(e) => setTime({ ...time, startingTime: e.target.value })}

                            />
                        </div>
                        <div>
                            <p>{t('Ending time')}:</p>
                            <input
                                className="mb-4 bg-slate-800 text-white dark border border-slate-800 text-gray-900 text-sm rounded p-2 mt-1"
                                type='time'
                                name='endingDate'
                                value={time.endingTime}
                                onChange={(e) => setTime({ ...time, endingTime: e.target.value })}
                            />
                        </div>
                    </div>
                    :
                    <div className='flex flex-row gap-6 md:gap-12'>
                        <div className=''>
                            <p>{t('Starting time')}:</p>
                            <input
                                className="mb-4 bg-gray-100 border border-slate-800 text-gray-900 text-sm rounded p-2 mt-1"
                                type='time'
                                name='startingTime'
                                onChange={(e) => setTime({ ...time, startingTime: e.target.value })}
                            />
                        </div>
                        <div>
                            <p>{t('Ending time')}:</p>
                            <input
                                className="mb-4 bg-gray-100 border border-slate-800 text-gray-900 text-sm rounded p-2 mt-1"
                                type='time'
                                name='endingDate'
                                onChange={(e) => setTime({ ...time, endingTime: e.target.value })}
                            />
                        </div>
                    </div>
                }
            </div>
            <div>
                <div>
                    {isTimeSet && isValidTime &&
                        <div>
                            <div>
                                {isTimeSaved && <p>{t('Competition time has been saved correctly')}</p>}
                            </div>

                            <button
                                className="border-2 rounded py-2 px-3 bg-green-500 hover:bg-green-500 font-bold text-white mt-2"
                                onClick={() => saveCompetitionTime()}
                            >
                                {t('Update time')}
                            </button>
                        </div>
                    }
                    {isTimeSet && !isValidTime &&
                        <ErrorNotice notice={t('Invalid time')} />
                    }
                </div>
            </div>
        </div>
    )
};

export default SaveCompetitionTime;