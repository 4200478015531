import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

const ConnectionInfo = ({participants}) => {

    const { t } = useTranslation()
    
    const [totalParticipants, setTotalParticipants] = useState(0) 

    const [connectedParticipants, setConnectedParticipants] = useState(0)

    
    useEffect(() => {
        
        const countTotalParticipants = participants.filter((participant) => !participant.data.out_of_competition).length 
        setTotalParticipants(countTotalParticipants)

        const connectedParticipants = participants.filter((participant) => participant.data.connected && !participant.data.out_of_competition).length
        setConnectedParticipants(connectedParticipants)

    },[participants])

    return (       
        <div className="p-1 mt-3">
            <p className="text-white text-xs ml-1">{t('Connected participants')}: {connectedParticipants}/{totalParticipants}</p>
        </div>
    )
};

export default ConnectionInfo;