import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      //CreateCompetition.js
      "Competition name": "Competition name",
      "Competition name is required": "Competition name is required",
      "Add Competition": "Add Competition",
      //CompetitionList.js
      "Name": "Name",
      "Status": "Status",
      "Actions": "Actions",
      "There are no competitions...": "There are no competitions...",
      //CompetitionBox.js
      "Upload a file with participants": "Upload a file with participants",
      "Set competition dates": "Set competition dates",
      "Upload a file with a track": "Upload a file with a track",
      "Not configured": "Not configured",
      "Everything is ready for the competition": "Everything is ready for the competition",
      "Last location sended at": "Last location sended at",

      //WatchCompetitionButton.js
      "Watch": "Watch",
      //UpdateCompetitionButton.js
      "Update": "Update",
      //DeleteCompetitionButton.js
      "Delete competition text": "Are you sure you want to delete this competition?",
      "Error deleting dates from competition": "Not possible to modify competition dates",

      //CompetitionView Page
      //ParticipantsList.js
      "Search": "Bib number, name or lastname",
      //WaypointList.js
      "Unregistered participants": "Unregistered participants",  
      "No participant has passed through the wayoint": "No participant has passed through the wayoint",    
      "All participants have passed through the waypoint": "All participants have passed through the waypoint",
      //ParticipantDataBox.js
      "No alerts": "No alerts",
      "No avaliable": "No avaliable",
      "No data": "No data",
      "Finish line at": "Finish line at",
      //ParticipantStatusCheckBox.js
      "Retired": "Not presented/Retired",
      //DeleteAlertButton.js
      "Are you sure you want to remove this alert?": "Are you sure you want to remove this alert?",
      //ConnectionInfo.js
      "Connected participants": "Connected participants",
      
      //CompetitionSettings page
      //CompetitionSettings.js
      "Competition setting": "Competition setting",
      //Date
      //CompetitionDate.js
      "Competition date": "Date and time",
      //CompetitionDateSetting.js
      "Starting date": "Starting date",
      "Starting time": "Starting time",
      "Ending date": "Ending date",
      "Ending time": "Ending time",
      "Set Date": "Set Date",
      "Fill all the fields": "Please fill in all fields",
      "Starting date and time error": "Starting date and time must be earlier than ending date and time.",
      "Wrong date": "Starting date must be earlier than ending date.",
      "Date warning": "Once the date is set, it can't be modified.",
      "Date": "Date",
      "Time": "Time",     
      "Invalid time": "Starting time must be earlier than ending time",
      "Competition time has been saved correctly": "Competition time has been saved correctly", 
      "Update time": "Update time",
      //DeleteCompetitionDateButton.js
      "Delete Competition Date": "Modify Dates",
      //Participants
      //AddParticipants.js
      "Generate": "Generate QR codes for participants",
      "Select range": "Select a range of bib numbers",
      "From": "From",
      "To": "To",
      "Participants": "Participants",
      "Upload a file to add participants": "Upload a file to add participants",
      "Number of bib numbers": "Number of bib numbers",
      "Over limit": "The maximum of bib numbers is 500",
      "Under limit": "The minimum of bib numbers is 5",
      "Generation warning": "Competition QR codes can't be deleted once generated",
      "You have created": "You have created",
      "bib numbers": "bib numbers",
      "from": "from",
      "to": "to",
      "Gen": "Generate",   
      "Generate new bib numbers": "Generate new bib numbers",
      "This can't be undone": "This can't be undone",
      "Zero Qr Error": "Bib number can't be 0",
      //BibQrTable.js
      "Select the fields": "Select the fields",
      "Select all the fields": "Select all the fields",
      "There are more bib numbers than qr codes": "There are more bib numbers than qr codes",      
      "There are more qr codes than bib numbers": "There are more qr codes than bib numbers",
      "Bib numbers must be positive integers": "Bib numbers must be positive integers",
      "There are duplicated bib numbers": "The file contains duplicated bib numbers",
      //ParticipantsTable.js
      "Participant Name": "Name",
      "Last Name": "Last Name",
      "Bib Number": "Bib Number",
      "Phone Number": "Phone Number",
      "Gender": "Gender",
      "Select": "Select",
      "Fill columns": "Fill all the columns before adding participants.",
      //MergeParticipantsData.js
      "Update participants": "Update participants",
      //SaveParticipantsButton.js
      "Save Participants": "Save Participants",
      //SavedParticipantsTable.js
      //DeleteParticipantsButton.js
      "Delete Participants": "Delete Participants",
      "Delete participants text": "Are you sure you want to delete the participants?",
      //DownloadTableButton.js
      "Download": "Download",
      //Track
      //CompetitionTrack.js
      "Competition track": "Track",
      "Upload a .gpx, .kmz or .kml file": "Upload a .gpx, .kmz or .kml file",
      "Upload track": "Upload track",
      "File size must be less than 250 KiB": "File size must be less than 250 KiB",
      //MapTrackSetting.js        
      //SaveWaypointButton.js
      "Set Waypoint": "Set Waypoint",
      "Max waypoints reached": "Max waypoints reached",
      //WaypointsTable.js
      "Index": "Index",
      "Delete": "Delete",
      "Haven't created waypoints yet": "Haven't created Waypoints yet",
      //DeleteCompetitionTrackButton.js
      "Delete Track": "Delete Track",
      "Delete track text": "Are you sure you want to delete the track?",

      //UserNavbar.js        
      //UserMenu.js      
      "Home": "Home",
      "Staff": "Staff",
      "Settings": "Settings",
      //SignOutButton.js
      "Sign out": "Sign out",

      //Config Page
      //LanguageSetting.js
      "Language": "Language",
      //PasswordSetting.js
      "Password": "Password",
      "Current password": "Current password",
      "New password": "New password",
      "Confirm password": "Confirm password",
      "Change password": "Change password",
      "Passwords do not match": "Passwords do not match",

      //ConfirmDeleteWindow.js
      "Cancel": "Cancel",

      //ParticipantMarker.js
      "Bib": "Bib",
      "Alert": "Alert out of track",
      "Share": "Safetrack - Location of bib ",

      //ErrorMessageWindow.js
      "Close": "Close",

      //Staff
      "Create new member": "Create new member",
      "person": "Person",
      "bicycle": "Bicycle",
      "car": "Car",
      "ambulance": "Ambulance",
      "lifeguard": "Lifeguard",
      "surveillance": "Surveillance",
      "boat": "Boat",
      "optional": "optional",
      "add staff": "Add staff to competition",
      "select member": "Select a member",
      "Select role": "Select role",
      "You have not created staff yet": "You have not created staff yet",
      "Start creating staff": "You can start creating staff here",
      "Can not assign staff": "You can't assign staff to the competition because you haven't created any members yet",
      "Create": "Create",
      "Add": "Add",
    }
  },
  es: {
    translation: {
      //Home Page        
      //CreateCompetition.js
      "Competition name": "Nombre de la competición",
      "Competition name is required": "El nombre de la competición es obligatorio",
      "Add Competition": "Añadir competición",
      //CompetitionList.js
      "Name": "Nombre",
      "Status": "Estado",
      "Actions": "Acciones",
      "There are no competitions...": "No hay competiciones...",
      //CompetitionBox.js
      "Upload a file with participants": "Sube un archivo con los participantes",
      "Set competition dates": "Establece las fechas de la competición",
      "Upload a file with a track": "Sube un archivo con el circuito",
      "Not configured": "No configurado",
      "Everything is ready for the competition": "Todo listo para la competición",
      "Last location sended at": "Última ubicación enviada a las",
      //MapMiniature.js
      "No track added": "No has configurado el circuito",
      //WatchCompetitionButton.js
      "Watch": "Ver",
      //UpdateCompetitionButton.js
      "Update": "Configurar",
      //DeleteCompetitionButton.js
      "Delete competition text": "¿Estás seguro de que quieres borrar esta competición?",
      //CompetitionView Page
      //ParticipantsList.js
      "Search": "Número de dorsal, nombre o apellidos",
      //WaypointList.js
      "Unregistered participants": "Participantes no registrados",
      "No participant has passed through the wayoint": "Ningún participante ha pasado por el waypoint",
      "All participants have passed through the waypoint": "Todos los participantes han pasado por el waypoint",
      //ParticipantDataBox.js
      "No alerts": "Sin alertas",
      "No avaliable": "No disponible",
      "No data": "Sin datos",
      "Finish line at": "Línea de meta a las",
      //ParticipantStatusCheckBox.js
      "Retired": "No presentado/Retirado",
      //DeleteAlertButton.js
      "Are you sure you want to remove this alert?": "¿Estás seguro de que quieres borrar esta alerta?",
      //ConnectionInfo.js
      "Connected participants": "Participantes conectados",

      //CompetitionSettings page
      //CompetitionSettings.js
      "Competition setting": "Configurar competición",
      //Date
      //CompetitionDate.js
      "Competition date": "Fecha y hora",
      //CompetitionDateSetting.js
      "Starting date": "Fecha de inicio",
      "Starting time": "Hora de inicio",
      "Ending date": "Fecha de fin",
      "Ending time": "Hora de fin",
      "Set Date": "Guardar fechas",
      "Fill all the fields": "Debes rellenar todos los campos",
      "Starting date and time error": "La fecha y hora de inicio debe ser anterior a la fecha y hora de fin.",
      "Wrong date": "La fecha de inicio debe ser anterior a la fecha de fin.",
      "Date warning": "Una vez establecida la fecha, no podrá ser modificada.",
      "Date": "Fecha",
      "Time": "Hora",
      "Invalid time": "La hora de inicio debe ser anterior a la hora de fin",
      "Competition time has been saved correctly": "La fecha de la competición se ha guardado correctamente",
      "Update time": "Actualizar hora",
      //DeleteCompetitionDateButton.js
      "Delete Competition Date": "Modificar fechas",
      "Error deleting dates from competition": "No es posible modificar las fechas de la competición",
      //Participants
      //AddParticipants.js
      "Generate": "Generar códigos QR para los participantes",
      "Select range": "Selecciona un rango de dorsales",
      "From": "Desde",
      "To": "Hasta",
      "Number of bib numbers": "Número de dorsales",
      "Participants": "Participantes",
      "Upload a file to add participants": "Sube un archivo para añadir participantes",
      "Over limit": "El máximo de dorsales es 500",
      "Under limit": "El mínimo de dorsales es 5",
      "Generation warning": "No se podrán eliminar los códigos QR de la competición una vez generados",
      "You have created": "Has creado",
      "bib numbers": "dorsales",
      "from": "desde",
      "to": "hasta",
      "Gen": "Generar",
      "Generate new bib numbers": "Generar nuevos dorsales",
      "This can't be undone": "Esta acción no se puede deshacer",
      "Zero Qr Error": "El dorsal no puede ser 0",
      //BibQrTable.js
      "Select the fields": "Selecciona los campos",
      "Select all the fields": "Selecciona todos los campos",
      "There are more bib numbers than qr codes": "Hay más dorsales que códigos QR",
      "There are more qr codes than bib numbers": "Hay más códigos QR que dorsales",
      "Bib numbers must be positive integers": "Los dorsales deben ser números enteros positivos",
      "There are duplicated bib numbers": "El archivo contine dorsales duplicados",
      //ParticipantsTable.js
      "Participant Name": "Nombre",
      "Last Name": "Apellido",
      "Bib Number": "Dorsal",
      "Phone Number": "Teléfono",
      "Gender": "Género",
      "Select": "Seleccionar",
      "Fill columns": "Rellena todas las columnas antes de añadir participantes.",
      //MergeParticipantsData.js
      "Update participants": "Actualizar participantes",
      //SaveParticipantsButton.js
      "Save Participants": "Añadir Participantes",
      //SavedParticipantsTable.js
      //DeleteParticipantsButton.js
      "Delete Participants": "Borrar Participantes",
      "Delete participants text": "¿Estás seguro de que quieres borrar los participantes?",
      //DownloadTableButton.js
      "Download": "Descargar",
      //Track
      //CompetitionTrack.js
      "Competition track": "Circuito",
      "Upload a .gpx, .kmz or .kml file": "Sube un archivo .gpx, .kmz o .kml",
      "Upload track": "Añadir circuito",
      "File size must be less than 250 KiB": "El tamaño del archivo debe ser menor de 250 KiB",
      //MapTrackSetting.js        
      //SaveWaypointButton.js
      "Set Waypoint": "Añadir Waypoint",
      "Max waypoints reached": "Has alcanzado el máximo de Waypoints",
      //WaypointsTable.js
      "Index": "Índice",
      "Delete": "Borrar",
      "Haven't created waypoints yet": "Aún no has creado Waypoints",
      //DeleteCompetitionTrackButton.js
      "Delete Track": "Borrar Circuito",
      "Delete track text": "¿Estás seguro de que quieres borrar el circuito?",

      //UserNavbar.js
      //UserMenu.js
      "Home": "Inicio",
      "Staff": "Personal",
      "Settings": "Configuración",
      //SignOutButton.js
      "Sign out": "Salir",

      //Config Page
      //LanguageSetting.js
      "Language": "Idioma",
      //PasswordSetting.js
      "Password": "Contraseña",
      "Current password": "Contraseña actual",
      "New password": "Nueva contraseña",
      "Confirm password": "Confirmar contraseña",
      "Change password": "Cambiar contraseña",
      "Password changed successfully": "Contraseña cambiada correctamente",
      "Passwords do not match": "Las contraseñas no coinciden",
      //ConfirmDeleteWindow.js        
      "Cancel": "No",

      //ParticipantMarker.js
      "Bib": "Dorsal",
      "Alert": "Alerta fuera de circuito",
      "Share": "Safetrack - Localización del dorsal ",

      //ErrorMessageWindow.js
      "Close": "Cerrar",

      //Staff
      "Create new member": "Crear nuevo miembro",
      "person": "Persona",
      "bicycle": "Bicicleta",
      "car": "Coche",
      "ambulance": "Ambulancia",
      "lifeguard": "Socorrista",
      "surveillance": "Vigilancia",
      "boat": "Barco",      
      "optional": "opcional",
      "add staff": "Añadir miembro a la competición",
      "select member": "Selecciona un miembro",
      "Select role": "Selecciona un rol",   
      "You have not created staff yet": "Todavía no has creado personal",
      "Start creating staff": "Puedes empezar a crear personal aquí",
      "Can not assign staff": "No puedes asignar personal a la competición porque todavía no has creado ningún miembro",
      "Create": "Crear",
      "Add": "Añadir",
    }
  }
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options

  .init({
    resources,
    fallbackLng: localStorage.getItem('i18nextLng') || 'es',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;