import { utils, write } from 'xlsx'

import { useTranslation } from 'react-i18next';

import getParticipantsByCompetitionId from "../../services/getParticipantsByCompetitionId"

import { FaDownload } from "react-icons/fa";



const DowloadBibQrTableButton = ({ competitionId }) => {        
    
    const { t } = useTranslation();

    const downloadTable = async() => {
        const participants = await getParticipantsByCompetitionId(competitionId);
        
        const data = participants.map(participant => {
            return {               
                bib_number: participant.data.bib_number,               
                qr_url: participant.data.qr_url
            }
        });

        const worksheet = utils.json_to_sheet(data)

        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Participants')

        const wbout = write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${competitionId}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
    };  

   
    return(
        <div>
            <button 
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex gap-2 mt-6 mb-4" 
                onClick={() => downloadTable()}
            >
                <FaDownload />{t('Download')}
            </button>
        </div>    
    )
};

export default DowloadBibQrTableButton;
